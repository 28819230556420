import * as React from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useAuth } from "../../../providers";
import { Divider } from "@mui/material";

export default function OwnerFilter(props) {
  const { authUser } = useAuth();
  const [name, setName] = React.useState(
    `${authUser.name.firstName} ${authUser.name.lastName}`
  );
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Button
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        endIcon={open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
      >
        {name}
      </Button>

      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {authUser.otherOwners &&
          Object.keys(authUser.otherOwners).length > 0 &&
          Object.entries(authUser.otherOwners).map(([key, value]) => (
            <MenuItem key={key} onClick={handleClose}>
              {value.name}
            </MenuItem>
          ))}
        <Divider />
        <MenuItem onClick={() => alert("To be added")}>Add New</MenuItem>
      </Menu>
    </div>
  );
}
