import React, { useState, useEffect } from "react";
import { compose } from "recompose";

import { withFirebase } from "../Firebase";
import { withRouter } from "react-router-dom";
import * as ROUTES from "../../constants/routes";

import PageLayout from "../Layout/PageLayout";
import { Typography, Button, TextField, Grid, Box } from "@mui/material";
import logo from "../../assets/logo192.png";
import CardSection from "../Layout/CardSection";

const SignIn = (props) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [checked, setChecked] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  // const Toast = () => {
  //    return (
  //       <div>
  //          <Collapse in={checked} collapsedHeight={0}>
  //             <Alert severity="error">
  //                <strong>Error - </strong>
  //                {errorMessage}
  //             </Alert>
  //          </Collapse>
  //       </div>
  //    );
  // };

  function handleSubmit(event) {
    event.preventDefault();
    props.firebase
      .doSignInWithEmailAndPassword(email, password)
      .then(() => {
        props.history.push(ROUTES.DASHBOARD);
      })
      .catch((error) => {
        setErrorMessage(error.message);
        setChecked(true);
      });
  }

  return (
    <PageLayout>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <CardSection>
          {/* <Toast /> */}

          <React.Fragment>
            {/* <Avatar className={classes.avatar}>
                     <LockOutlinedIcon />
                  </Avatar> */}
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <img width="192px" height="192px" src={logo} />
              <Typography component="h1" variant="h5" align="center">
                Sign in
              </Typography>
            </Box>
            {/* <Button
              fullWidth
              // href={ROUTES.AUTH}
              variant="outlined"
              sx={{
                maxHeight: "41px",
                minHeight: "41px",
                borderWidth: 1,
                borderColor: "#8C8C8C",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  flexWrap: "noWrap",
                }}
              >
                <Icon className={classes.mssignin}>
                           <img src={MsSymbol} />
                        </Icon>
                <Typography noWrap>Sign in with Microsoft</Typography>
              </div>
            </Button> */}
            {/* <Typography component="p" variant="h6" gutterBottom>
              Or
            </Typography> */}
            {/* <Divider style={{ width: "100%", marginBottom: "8px" }} /> */}
            {/* <Typography variant="body1">
              Sign in with your email address
            </Typography> */}
            <form onSubmit={handleSubmit} noValidate>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                value={email}
                onInput={(e) => setEmail(e.target.value)}
                autoFocus
              />
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                value={password}
                onInput={(e) => setPassword(e.target.value)}
                autoComplete="current-password"
              />

              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="secondary"
                sx={{ mt: 2 }}
              >
                Sign In
              </Button>

              <Grid container>
                {/* <Grid item xs>
                  <Link href={ROUTES.PASSWORD_FORGET} variant="body2">
                    Forgot password?
                  </Link>
                </Grid> */}
                {/* <Grid item>
                           <Link href={ROUTES.SIGN_UP} variant="body2">
                              {"Don't have an account? Sign Up"}
                           </Link>
                        </Grid> */}
              </Grid>
            </form>
          </React.Fragment>
        </CardSection>
      </Box>
    </PageLayout>
  );
};

const SignInForm = compose(withRouter, withFirebase)(SignIn);

export default SignInForm;
