import React, { useState } from "react";
import { withFirebase } from "../Firebase";
import {
  Box,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  Button,
  RadioGroup,
  TextField,
  Typography,
  Select,
  InputLabel,
  MenuItem,
} from "@mui/material";
import { useAuth } from "../../providers";

const NewAccountForm = (props) => {
  const { authUser } = useAuth();
  const [account, setAccount] = useState({
    name: "",
    owners: "individual",
    variant: "personal",
    type: "current",
    balance: 0,
    currency: "GBP",
    pendingTransactions: 0,
    lastTransactionDate: null,
  });

  async function createAccount() {
    if (account.name !== "") {
      const batch = props.firebase.db.batch();
      const newAccountRef = props.firebase.db
        .collection(`users/${authUser.uid}/accounts`)
        .doc();
      batch.set(newAccountRef, account);
      const userRef = props.firebase.db.doc(`users/${authUser.uid}`);
      batch.set(newAccountRef, account, { merge: true });
      let userAccounts = [];
      if (authUser.accounts) {
        userAccounts = authUser.accounts;
      }
      userAccounts.push({ aid: newAccountRef.id, name: account.name });
      batch.set(userRef, { accounts: userAccounts }, { merge: true });
      const indexRef = props.firebase.db.doc(
        `users/${authUser.uid}/indexes/accounts`
      );
      batch.set(
        indexRef,
        { [newAccountRef.id]: { title: account.name, order: 999 } },
        { merge: true }
      );
      try {
        await batch.commit();
        props.setOpen(false);
      } catch (error) {
        console.warn("Error creating account: ", error);
      }
    }
  }

  return (
    <Box sx={{ w: 300, display: "flex", flexDirection: "column" }}>
      <Typography variant="h6">New Account Form</Typography>
      <TextField
        fullWidth
        id="name"
        label="Account Name"
        variant="outlined"
        value={account.name}
        onChange={(e) => setAccount({ ...account, name: e.target.value })}
      />
      <FormControl sx={{ mt: 1 }}>
        <FormLabel id="account-type">Variant</FormLabel>
        <RadioGroup
          name="account-variant"
          value={account.variant}
          onChange={(e) => setAccount({ ...account, variant: e.target.value })}
        >
          <FormControlLabel
            value="personal"
            control={<Radio />}
            label="Personal"
          />
          <FormControlLabel
            value="business"
            control={<Radio />}
            label="Business"
          />
        </RadioGroup>
      </FormControl>
      <FormControl>
        <FormLabel id="account-owners">Owners</FormLabel>
        <RadioGroup
          name="account-owners"
          value={account.owners}
          onChange={(e) => setAccount({ ...account, owners: e.target.value })}
        >
          <FormControlLabel
            value="individual"
            control={<Radio />}
            label="Individual"
          />
          <FormControlLabel value="joint" control={<Radio />} label="Joint" />
        </RadioGroup>
      </FormControl>
      <FormControl fullWidth>
        <FormLabel id="account-type">Type</FormLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={account.type}
          label="Type"
          onChange={(e) => setAccount({ ...account, type: e.target.value })}
        >
          <MenuItem value={"current"}>Current</MenuItem>
          <MenuItem value={"credit"}>Credit Card</MenuItem>
          <MenuItem value={"savings"}>Savings</MenuItem>
        </Select>
      </FormControl>
      <Box sx={{ display: "flex", justifyContent: "space-between", mt: 2 }}>
        <Button color="secondary" onClick={() => props.setOpen(false)}>
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={() => createAccount()}
        >
          Create
        </Button>
      </Box>
    </Box>
  );
};

export default withFirebase(NewAccountForm);
