import firebase from "firebase/compat/app";
import { findIdByTitle, recalculateBalances } from "./index";
import {
  accountRef,
  transDateRef,
  transactionRef,
  increment,
} from "../../../firebaseRefs";

export default async function explainTransferTransaction(
  authUser,
  form,
  transaction,
  loadedAccount,
  accounts
) {
  let pairingAccountId = await findIdByTitle(accounts, form.account);
  if (!pairingAccountId) {
    alert(`This account doesn't exist`);
    return;
  } else {
    const pairTransaction = await transDateRef(
      authUser.uid,
      pairingAccountId,
      form.dateRef
    ).get();
    let exists = false;
    let otherAccountTransactionId = {};
    if (pairTransaction.exists) {
      Object.entries(pairTransaction.data()).forEach(([key, value]) => {
        if (
          key !== "balances" &&
          -value.amount === transaction.amount &&
          !value.reconciled
        ) {
          exists = true;
          otherAccountTransactionId.id = key;
        }
      });
    }
    const batch = firebase.firestore().batch();

    let thisAccountTran = {
      ...form,
      account: pairingAccountId,
      reconciled: true,
      category: "Transfer",
    };
    let otherAccountTran = {
      ...form,
      account: loadedAccount.aid,
      otherTid: form.transactionId,
      amount: -transaction.amount,
      reconciled: true,
      category: "Transfer",
    };
    if (exists) {
      batch.set(
        accountRef(authUser.uid, pairingAccountId),
        { pendingTransactions: increment(-1) },
        { merge: true }
      );
    } else {
      otherAccountTransactionId = await firebase
        .firestore()
        .collection(
          `users/${authUser.uid}/accounts/${pairingAccountId}/allTransactions`
        )
        .doc()
        .get();
    }
    thisAccountTran.otherTid = otherAccountTransactionId.id;
    otherAccountTran.transactionId = otherAccountTransactionId.id;

    batch.set(
      accountRef(authUser.uid, loadedAccount.aid),
      { pendingTransactions: firebase.firestore.FieldValue.increment(-1) },
      { merge: true }
    );

    batch.set(
      transactionRef(authUser.uid, loadedAccount.aid, form.transactionId),
      thisAccountTran,
      { merge: true }
    );

    batch.set(
      transDateRef(authUser.uid, loadedAccount.aid, form.dateRef),
      { [form.transactionId]: thisAccountTran },
      { merge: true }
    );

    batch.set(
      transactionRef(
        authUser.uid,
        pairingAccountId,
        otherAccountTransactionId.id
      ),
      otherAccountTran,
      { merge: true }
    );
    batch.set(
      transDateRef(authUser.uid, pairingAccountId, form.dateRef),
      { [otherAccountTransactionId.id]: otherAccountTran },
      { merge: true }
    );
    try {
      await batch.commit();
      if (!exists) {
        await recalculateBalances(authUser.uid, pairingAccountId, form.dateRef);
      }
    } catch (err) {
      console.error(err, "Error explaining transfer transaction");
    }
  }
}
