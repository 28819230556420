import React from "react";
import { Box, CircularProgress, Typography } from "@mui/material";

const LoadingThrobber = ({ sx, ...rest }) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <CircularProgress />
      <Typography variant="caption">Loading</Typography>
    </Box>
  );
};

export default LoadingThrobber;
