import { Box, Stack, Typography, useMediaQuery } from "@mui/material";
import React, { useEffect, useState } from "react";
import PageLayout from "../Layout/PageLayout";
import { useAuth } from "../../providers";

import { withFirebase } from "../Firebase";

const Main = (props) => {
  const { authUser } = useAuth();

  return (
    <PageLayout>
      <Box>Assets</Box>
    </PageLayout>
  );
};

export default withFirebase(Main);
