import { Box, Button, Modal, Typography } from "@mui/material";
import React, { useState } from "react";
import ImportCsv from "./importCsv";
import { withFirebase } from "../Firebase";
import { useAuth } from "../../providers";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%",
  backgroundColor: "white",
  boxShadow: 24,
  p: 4,
};

const Imports = ({ loadedAccount, firebase }) => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const { authUser } = useAuth();
  console.log(authUser);

  return (
    <Box>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Box>
          <Typography variant="h5">Pending</Typography>
        </Box>
        <Box>
          <Typography variant="body1" paragraph>
            {loadedAccount.pendingTransactions}
          </Typography>
        </Box>
      </Box>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Box sx={{ mr: 1 }}>
          <Typography variant="caption" paragraph>
            Imported transactions are Pending until explained
          </Typography>
        </Box>
        <Box>
          <Button
            disabled={!loadedAccount}
            variant="outlined"
            size="small"
            onClick={handleOpen}
          >
            Import
          </Button>
        </Box>
      </Box>
      {/* <Button variant="outlined" size="small" onClick={rectifyPending}>
        Rectify pending transaction numbers
      </Button> */}
      <Modal open={open} onClose={handleClose}>
        <Box sx={style}>
          <ImportCsv loadedAccount={loadedAccount} setOpen={setOpen} />
        </Box>
      </Modal>
    </Box>
  );
};

export default withFirebase(Imports);
