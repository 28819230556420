export const returnDbTerm = (field) => {
  switch (field) {
    case "counterParty":
      return "counterparties";
    case "category":
      return "paymentCategories";
    case "type":
      return "transactionTypes";
    case "project":
      return "projects";
    case "assets":
      return "assets";
    default:
      return;
  }
};
