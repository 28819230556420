import React, { useState, useEffect } from "react";
import { useAuth } from "../../providers";
import { Box, Button, Modal, Typography } from "@mui/material";
import CardSection from "../Layout/CardSection";
import { withFirebase } from "../Firebase";
import NewAccountForm from "./newAccountForm";
import * as ROUTES from "../../constants/routes";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  backgroundColor: "white",
  boxShadow: 24,
  p: 4,
};

const AccountSummary = (props) => {
  const { authUser } = useAuth();
  const [accounts, setAccounts] = useState(false);
  const [open, setOpen] = React.useState(false);

  useEffect(() => {
    const unsubscribe = props.firebase.db
      .collection(`users/${authUser.uid}/accounts`)
      .onSnapshot((snapshot) => {
        if (snapshot.size) {
          let accounts = [];
          snapshot.forEach((doc) => {
            accounts.push({ ...doc.data(), id: doc.id });
          });
          accounts.sort((a, b) => a.name.localeCompare(b.name));
          setAccounts(accounts);
        } else {
          setAccounts(false);
        }
      });
    return () => unsubscribe();
  }, []);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <CardSection>
      <Modal open={open} onClose={handleClose}>
        <Box sx={style}>
          <NewAccountForm setOpen={setOpen} />
        </Box>
      </Modal>

      <Typography variant="h5">Accounts</Typography>
      {accounts ? (
        <Box>
          {accounts.map((account) => (
            <Box key={account.id}>
              <Typography paragraph>{account.name}</Typography>
            </Box>
          ))}
          <Button href={ROUTES.ACCOUNTS}>Go to Accounts</Button>
        </Box>
      ) : (
        <Box>
          <Typography paragraph>No Accounts</Typography>
          <Button onClick={() => handleOpen()}>Add Account</Button>{" "}
        </Box>
      )}
    </CardSection>
  );
};

export default withFirebase(AccountSummary);
