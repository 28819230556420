import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";
import firebase from "firebase/compat/app";
import { withFirebase } from "../Firebase";
import * as ROUTES from "../../constants/routes";

import * as EmailValidator from "email-validator";
import { TextField, Button, Box, Typography } from "@mui/material";
import PageLayout from "../Layout/PageLayout/PageLayout";
import CardSection from "../Layout/CardSection/CardSection";

const SignUpFormBase = (props) => {
  const [user, setUser] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    password2: "",
  });
  const [error, setError] = useState(false);
  const [disabled, setDisabled] = useState(false);

  async function handleSubmit() {
    var valid = true;
    if (
      user.firstName === "" ||
      user.lastName === "" ||
      user.email === "" ||
      !EmailValidator.validate(user.email) ||
      user.password === "" ||
      user.password2 === ""
    ) {
      setError(true);
      valid = false;
    }
    if (user.password !== user.password2) {
      setError(true);
      valid = false;
    }

    if (valid === true) {
      setDisabled(true);
      await props.firebase
        .doCreateUserWithEmailAndPassword(user.email, user.password)
        .then(async (authUser) => {
          var newUser = await firebase.auth().currentUser;
          newUser.updateProfile({
            displayName: user.firstName + " " + user.lastName,
          });
          const batch = props.firebase.db.batch();
          const userRef = props.firebase.user(authUser.user.uid);
          batch.set(userRef, {
            name: { firstName: user.firstName, lastName: user.lastName },
            email: user.email,
            uid: authUser.user.uid,
          });
          const indexRef = props.firebase.db.doc(
            `users/${authUser.user.uid}/indexes/counterparties`
          );
          batch.set(indexRef, {
            [authUser.user.uid]: {
              title: `${user.firstName} ${user.lastName} (ME)`,
              order: 1,
            },
          });
          const counterPartyRef = props.firebase.db.doc(
            `users/${authUser.user.uid}/counterparties/${authUser.user.uid}`
          );
          batch.set(counterPartyRef, {
            balance: 0,
            credit: 0,
            debit: 0,
            favourite: true,
            loan: 0,
            title: `${user.firstName} ${user.lastName} (ME)`,
            order: 1,
          });
          const paymentIndexRef = props.firebase.db.doc(
            `users/${authUser.user.uid}/indexes/paymentCategories`
          );
          batch.set(paymentIndexRef, {
            1: { title: "Income", order: 1 },
            2: { title: "Payment", order: 2 },
            3: { title: "Loan", order: 3 },
            4: { title: "Gift", order: 4 },
            5: { title: "Transfer", order: 5 },
          });

          try {
            await batch.commit();
            await props.firebase.doSendEmailVerification();
            props.history.push(ROUTES.DASHBOARD);
            setDisabled(false);
            setError(false);
          } catch (error) {
            console.warn(error, "ERROR SIGNING UP");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }

  return (
    <PageLayout>
      {error && <Typography>Error</Typography>}
      <CardSection
        sx={{
          display: "flex",
          flexDirection: "column",
          maxWidth: 250,
          m: "auto",
        }}
      >
        <Typography variant="h6" align="center">
          Sign Up
        </Typography>
        <TextField
          value={user.firstName}
          onChange={(e) => setUser({ ...user, firstName: e.target.value })}
          label="First Name"
          variant="outlined"
          sx={{ mt: 1 }}
        />
        <TextField
          value={user.lastName}
          onChange={(e) => setUser({ ...user, lastName: e.target.value })}
          label="Last Name"
          variant="outlined"
          sx={{ mt: 1 }}
        />
        <TextField
          value={user.email}
          onChange={(e) => setUser({ ...user, email: e.target.value })}
          label="Email"
          variant="outlined"
          sx={{ mt: 1 }}
        />
        <TextField
          value={user.password}
          onChange={(e) => setUser({ ...user, password: e.target.value })}
          label="Password"
          variant="outlined"
          type="password"
          sx={{ mt: 1 }}
        />
        <TextField
          value={user.password2}
          onChange={(e) => setUser({ ...user, password2: e.target.value })}
          label="Confirm Password"
          variant="outlined"
          type="password"
          sx={{ mt: 1 }}
        />
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Button
            onClick={() => handleSubmit()}
            variant="contained"
            color="secondary"
            disabled={error}
            fullWidth
            sx={{ mt: 2 }}
          >
            Sign up
          </Button>
        </Box>
      </CardSection>
    </PageLayout>
  );
};

const SignUpForm = compose(withRouter, withFirebase)(SignUpFormBase);

export default SignUpForm;
