export const transactionFields = [
  "counterParty",
  "category",
  "type",
  "project",
  "assets",
];

export const dbTerms = [
  "counterparties",
  "projects",
  "assets",
  "paymentCategories",
  "transactionTypes",
];
