import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import NewContact from "./newContact";
import CardSection from "../Layout/CardSection";
import AllParties from "./allParties";

const Main = () => {
  const [filter, setFilter] = useState("counterparties");
  return (
    <Box>
      <CardSection>
        <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
          <InputLabel id="demo-simple-select-standard-label">
            Entities
          </InputLabel>
          {console.log(filter)}
          <Select
            labelId="demo-simple-select-standard-label"
            id="demo-simple-select-standard"
            value={filter}
            onChange={(e) => setFilter(e.target.value)}
            label="Entities"
          >
            <MenuItem value="counterparties">Parties</MenuItem>
            <MenuItem value="paymentCategories">Payment Categories</MenuItem>
            <MenuItem value="projects">Projects</MenuItem>
            <MenuItem value="assets">Assets</MenuItem>
            <MenuItem value="transactionTypes">Transaction Types</MenuItem>
          </Select>
        </FormControl>
        {/* <NewContact /> */}
        <AllParties filter={filter} />
      </CardSection>
    </Box>
  );
};

export default Main;
