import React from "react";

import { compose } from "recompose";
import { AuthProvider } from "../../providers";
import { withAuthorization, withEmailVerification } from "../Session";
import Main from "./main";

const Settings = (props) => {
  return (
    <AuthProvider>
      <Main sx={{ m: 2 }} />
    </AuthProvider>
  );
};

const condition = (authUser) => !!authUser;

export default compose(
  withEmailVerification,
  withAuthorization(condition)
)(Settings);
