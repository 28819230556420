import React, { useState } from "react";
import { withFirebase } from "../Firebase";
import { Box, Button, Modal, TextField } from "@mui/material";
import { useAuth } from "../../providers";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  backgroundColor: "white",
  boxShadow: 24,
  p: 4,
};

const fieldStyling = {
  m: 1,
};

const NewContact = (props) => {
  const { authUser } = useAuth();
  const [open, setOpen] = useState(false);
  const [form, setForm] = useState({
    firstName: "",
    lastName: "",
    email: "",
  });

  const handleClose = () => {
    setOpen(false);
  };

  async function saveNew() {
    try {
      const batch = props.firebase.db.batch();
      const indexRef = props.firebase.db.doc(
        `users/${authUser.uid}/contacts/index`
      );
      const contactRef = props.firebase.db
        .collection(`users/${authUser.uid}/contacts`)
        .doc();
      batch.set(contactRef, form, { merge: true });
      batch.set(indexRef, { contactRef: form }, { merge: true });
      await batch.commit();
      handleClose();
    } catch (error) {
      console.warn(error, "ERROR SAVING NEW CONTACT");
    }
  }
  return (
    <>
      <Modal open={open} onClose={handleClose}>
        <Box sx={style}>
          <Box>
            <TextField
              value={form.firstName}
              onChange={(e) => {
                setForm({ ...form, firstName: e.target.value });
              }}
              label="First Name"
              fullWidth
              sx={fieldStyling}
            />
            <TextField
              value={form.lastName}
              onChange={(e) => {
                setForm({ ...form, lastName: e.target.value });
              }}
              label="Last Name"
              fullWidth
              sx={fieldStyling}
            />
            <TextField
              value={form.email}
              onChange={(e) => setForm({ ...form, email: e.target.value })}
              label="Email"
              fullWidth
              sx={fieldStyling}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              mt: 2,
            }}
          >
            <Button
              onClick={() => saveNew()}
              variant="contained"
              color="primary"
            >
              Add
            </Button>
            <Button onClick={handleClose}>Close</Button>
          </Box>
        </Box>
      </Modal>
      <Button onClick={() => setOpen(true)}>Add Contact</Button>
    </>
  );
};

export default withFirebase(NewContact);
