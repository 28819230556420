import React from "react";
import { Route, BrowserRouter as Router } from "react-router-dom";
import logo from "../../logo.svg";
import "../../App.css";
import { compose } from "recompose";
// import { ThemeProvider } from '@mui/material/styles';
import { withFirebase } from "../Firebase";
import { withAuthentication } from "../Session";
import { Box } from "@mui/material";
import DashboardPage from "../Dashboard";
import SignInPage from "../SignIn";
import LogOutPage from "../logOut";
import SignUpPage from "../SignUp";
import Entities from "../Entities";
import Settings from "../Settings";
import AccountsPage from "../Accounts";
import AssetsPage from "../Assets";
import * as ROUTES from "../../constants/routes";
import ResponsiveAppBar from "../AppBar";
// import { createMuiTheme } from '@mui/material/styles';
import useMediaQuery from "@mui/material/useMediaQuery";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

// const theme = React.useMemo(
//   () =>
//      createMuiTheme({
//         // palette: {
//         //    type: prefersDarkMode ? 'dark' : 'light',
//         //    primary: {
//         //       main: prefersDarkMode ? '#32BFC7' : '#342E76',
//         //    },
//         //    secondary: {
//         //       main: prefersDarkMode ? '#DB3D28' : '#DB3D28',
//         //    },
//         //    teal: {
//         //       main: prefersDarkMode ? '#32BFC7' : '#32BFC7',
//         //    },
//         //    tableHead: {
//         //       main: prefersDarkMode ? '#303030' : '#fafafa',
//         //    },
//         //    tableBody: {
//         //       main: prefersDarkMode ? '#424242' : '#fff',
//         //    },
//         //    cardHeader: {
//         //       main: prefersDarkMode ? '#646464' : '#fff',
//         //    },
//         //    cardAction: {
//         //       main: prefersDarkMode ? '#303030' : '#fafafa',
//         //    },
//         //    text: {
//         //       primary: prefersDarkMode ? '#fff' : '#000',
//         //       secondary: prefersDarkMode ? '#fff' : '#646464',
//         //       success: prefersDarkMode ? '#9ECF99' : 'green',
//         //       warning: prefersDarkMode ? '#F7E943' : '#F7C843',
//         //    },
//         //    whiteButtonHoverText: {
//         //       primary: prefersDarkMode ? '#342E76' : '#fff',
//         //    },
//         //    ito: {
//         //       main: prefersDarkMode ? '#32BFC7' : '#342E76',
//         //    },
//         //    wfh: {
//         //       main: prefersDarkMode ? '#E6E6E6' : '#32BFC7',
//         //    },
//         //    away: {
//         //       main: prefersDarkMode ? '#DB3D28' : '#DB3D28',
//         //    },
//         //    offsite: {
//         //       main: prefersDarkMode ? '#346E9A' : '#646464',
//         //    },
//         //    capacityBackground: {
//         //       main: prefersDarkMode ? '#646464' : '#E6E6E6',
//         //    },
//         //    success: {
//         //       main: '#9ECF99',
//         //    },
//         //    warning: {
//         //       main: '#F7E943',
//         //    },
//         //    availableTime: {
//         //       main: '#ff9900',
//         //    },
//         // },
//         // components: {
//         //    MuiTableCell: {
//         //       styleOverrides: {
//         //          root: {
//         //             borderBottomColor: 'rgba(224, 224, 224, 1)',
//         //          },
//         //       },
//         //    },
//         //    MuiTableFooter: {
//         //       styleOverrides: {
//         //          root: {
//         //             position: 'sticky',
//         //             zIndex: 999,
//         //             bottom: 0,
//         //             backgroundColor: prefersDarkMode ? '#424242' : '#fff',
//         //          },
//         //       },
//         //    },
//         // },
//      }),
//   [
//   //  prefersDarkMode
//   ],
// );

function App() {
  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");
  const customTheme = createTheme({
    type: prefersDarkMode ? "dark" : "light",
    palette: {
      primary: {
        main: prefersDarkMode ? "#10CCF3" : "#243D4D",
      },
      secondary: {
        main: prefersDarkMode ? "#243D4D" : "#10CCF3",
      },
      status: {
        warning: prefersDarkMode ? "#FDA934" : "#FDA934",
        error: prefersDarkMode ? "#DD4044" : "#DD4044",
        success: prefersDarkMode ? "#60DCB7" : "60DCB7",
      },
      green: {
        main: prefersDarkMode ? "#32CC9E" : "#32CC9E",
      },
    },
    typography: {
      h1: {
        fontSize: "2.5rem",
        fontWeight: 500,
      },
      h2: {
        fontSize: "1.6rem",
        fontWeight: 600,
      },
    },
    // components: {
    //   MuiTypography: {
    //     styleOverrides: {
    //       root: {
    //         borderBottomColor: "rgba(224, 224, 224, 1)",
    //       },
    //     },
    //   },
    // },
  });
  return (
    <Router>
      <ThemeProvider theme={customTheme}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <React.Fragment>
            <ResponsiveAppBar />
            <main>
              <Route exact path={ROUTES.DASHBOARD} component={DashboardPage} />
              <Route path={ROUTES.SIGN_IN} component={SignInPage} />
              <Route path={ROUTES.SIGN_UP} component={SignUpPage} />
              <Route path={ROUTES.ACCOUNTS} component={AccountsPage} />
              <Route path={ROUTES.ENTITIES} component={Entities} />
              <Route path={ROUTES.ASSETS} component={AssetsPage} />
              <Route path={ROUTES.SETTINGS} component={Settings} />
              <Route path={ROUTES.LOGOUT} component={LogOutPage} />
            </main>
          </React.Fragment>
        </LocalizationProvider>
      </ThemeProvider>
    </Router>
  );
}

const AppPage = compose(withFirebase)(App);

export default withAuthentication(AppPage);
