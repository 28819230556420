import firebase from "firebase/compat/app";
import {
  accountRef,
  increment,
  transactionRef,
  transDateRef,
} from "../../../firebaseRefs";
export default async function unExplainTransferTransaction(
  authUser,
  transaction,
  loadedAccount
) {
  const batch = firebase.firestore().batch();

  batch.set(
    transactionRef(authUser.uid, loadedAccount.aid, transaction.transactionId),
    { reconciled: false },
    { merge: true }
  );

  batch.set(
    transDateRef(authUser.uid, loadedAccount.aid, transaction.dateRef),
    { [transaction.transactionId]: { reconciled: false } },
    { merge: true }
  );

  batch.set(
    transactionRef(authUser.uid, transaction.account, transaction.otherTid),
    { reconciled: false },
    { merge: true }
  );

  batch.set(
    transDateRef(authUser.uid, transaction.account, transaction.dateRef),
    { [transaction.otherTid]: { reconciled: false } },
    { merge: true }
  );

  batch.set(
    accountRef(authUser.uid, loadedAccount.aid),
    { pendingTransactions: increment(1) },
    { merge: true }
  );

  batch.set(
    accountRef(authUser.uid, transaction.account),
    { pendingTransactions: increment(1) },
    { merge: true }
  );
  try {
    await batch.commit();
  } catch (err) {
    console.error(err, "Error unexplaining transfer transaction");
  }
}
