import React, { useEffect, useState } from "react";
import { withFirebase } from "../../Firebase";
import firebase from "firebase/compat/app";
import {
  Box,
  Autocomplete,
  TextField,
  Button,
  Typography,
} from "@mui/material";
import { useAuth } from "../../../providers";
import { explainNonTransferTransaction } from "./functions/explainNonTransferTransaction";
import unExplainTransferTransaction from "../functions/unExplainTransferTransaction";

const ExplainedTransaction = (props) => {
  const { authUser } = useAuth();
  const [form, setForm] = useState(false);
  const [paymentCategory, setPaymentCategory] = useState(false);
  const [transactionTypes, setTransactionTypes] = useState(false);
  const [projects, setProjects] = useState(false);
  const [counterParties, setCounterParties] = useState(false);
  const [ids, setIds] = useState({
    counterParty: null,
    category: null,
    type: null,
    project: null,
  });

  useEffect(() => {
    const unsubscribe = props.firebase.db
      .doc(`users/${authUser.uid}/indexes/paymentCategories`)
      .onSnapshot((snapshot) => {
        let categories = {};
        if (snapshot.exists) {
          Object.entries(snapshot.data()).map(([key, category]) => {
            categories[key] = { label: category.title, ...category };
          });
        }
        setPaymentCategory(categories);
      });
    return () => unsubscribe();
  }, []);
  useEffect(() => {
    const unsubscribe = props.firebase.db
      .doc(`users/${authUser.uid}/indexes/transactionTypes`)
      .onSnapshot((snapshot) => {
        let types = {};
        if (snapshot.exists) {
          Object.entries(snapshot.data()).map(([key, type]) => {
            types[key] = { label: type.title, ...type };
          });
        }
        setTransactionTypes(types);
      });
    return () => unsubscribe();
  }, []);

  useEffect(() => {
    const unsubscribe = props.firebase.db
      .doc(`users/${authUser.uid}/indexes/projects`)
      .onSnapshot((snapshot) => {
        let proj = {};
        if (snapshot.exists) {
          Object.entries(snapshot.data()).map(([key, project]) => {
            proj[key] = { label: project.title, ...project };
          });
        }
        setProjects(proj);
      });
    return () => unsubscribe();
  }, []);

  useEffect(() => {
    const unsubscribe = props.firebase.db
      .doc(`users/${authUser.uid}/indexes/counterparties`)
      .onSnapshot((snapshot) => {
        let parties = {};
        if (snapshot.exists) {
          console.log(snapshot.data(), "PARTIES");
          Object.entries(snapshot.data()).map(([key, type]) => {
            parties[key] = { label: type.title, ...type };
          });
        }
        setCounterParties(parties);
      });
    return () => unsubscribe();
  }, []);

  useEffect(() => {
    if (
      paymentCategory &&
      transactionTypes &&
      projects &&
      counterParties &&
      props.transaction.tid &&
      props.loadedAccount.aid
    ) {
      const unsubscribe = props.firebase.db
        .doc(
          `users/${authUser.uid}/accounts/${props.loadedAccount.aid}/allTransactions/${props.transaction.tid}`
        )
        .onSnapshot(
          (snapshot) => {
            let data = {
              counterParty: "",
              category: "",
              type: "",
              reference: "",
              description: "",
              attachment: null,
              project: null,
              split: false,
              party: null,
              reconciled: false,
              estimated: false,
            };
            if (snapshot.exists) {
              data = { ...data, ...snapshot.data() };
            }
            let idsToLoad = {
              category: null,
              type: null,
              project: null,
              counterParty: null,
            };
            if (counterParties && counterParties[data.counterParty]) {
              idsToLoad = {
                ...idsToLoad,
                counterParty: {
                  id: data.counterParty,
                  ...counterParties[data.counterParty],
                },
              };
              data.counterParty = counterParties[data.counterParty].label;
            }
            if (paymentCategory && paymentCategory[data.category]) {
              idsToLoad = {
                ...idsToLoad,
                category: {
                  id: data.category,
                  ...paymentCategory[data.category],
                },
              };
              data.category = paymentCategory[data.category].label;
            }
            if (transactionTypes && transactionTypes[data.type]) {
              idsToLoad = {
                ...idsToLoad,
                type: { id: data.type, ...transactionTypes[data.type] },
              };
              data.type = transactionTypes[data.type].label;
            }
            if (projects && projects[data.project]) {
              idsToLoad = {
                ...idsToLoad,
                project: { id: data.project, ...projects[data.project] },
              };
              data.project = projects[data.project].label;
            }
            setIds(idsToLoad);
            setForm(data);
          },
          (error) => {
            console.error(error, "ERROR LOADING TRANSACTION");
          }
        );

      return () => {
        unsubscribe();
      };
    }
  }, [
    props.transaction.tid,
    props.loadedAccount.aid,
    paymentCategory,
    transactionTypes,
    projects,
    counterParties,
  ]);

  function handleClose() {
    setForm(false);
    props.setExpanded(false);
  }
  async function unExplainTransaction() {
    if (form.category === "Transfer") {
      await unExplainTransferTransaction(
        authUser,
        props.transaction,
        props.loadedAccount
      );
    } else {
      explainNonTransferTransaction(
        authUser.uid,
        props.loadedAccount.aid,
        props.transaction,
        form,
        false
      ).then(() => handleClose());
      // await unExplainNonTransferTransaction();
    }
  }
  async function unExplainNonTransferTransaction() {
    let doubleEntryTransaction = { credit: 0, debit: 0, loan: 0 };
    let myTransaction = { credit: 0, debit: 0, loan: 0 };
    if (props.transaction.amount < 0) {
      myTransaction.debit = props.transaction.amount;
      doubleEntryTransaction.credit = props.transaction.amount;
    } else if (props.transaction.amount > 0) {
      myTransaction.credit = -props.transaction.amount;
      doubleEntryTransaction.debit = -props.transaction.amount;
    }
    if (props.transaction.category === "Loan") {
      doubleEntryTransaction.loan = props.transaction.amount;
      myTransaction.loan = -props.transaction.amount;
    }

    const fields = ["counterParty", "category", "type", "project"];

    const batch = props.firebase.db.batch();

    for (const field of fields) {
      let dbTerm = "";
      let termArray = [];
      switch (field) {
        case "counterParty":
          dbTerm = "counterparties";
          termArray = counterParties;
          break;
        case "category":
          dbTerm = "paymentCategories";
          termArray = paymentCategory;
          break;
        case "type":
          dbTerm = "transactionTypes";
          termArray = transactionTypes;
          break;
        case "project":
          dbTerm = "projects";
          termArray = projects;
          break;
        default:
          break;
      }
      if (form[field] !== "" && form[field] !== null) {
        console.log({ ids });
        let name = ids[field].label;
        if (field === "counterParty" || field === "project") {
          const ref = props.firebase.db.doc(
            `users/${authUser.uid}/${dbTerm}/${ids[field].id}/transactions/${form.dateRef}`
          );
          batch.set(
            ref,
            { [props.transaction.tid]: firebase.firestore.FieldValue.delete() },
            { merge: true }
          );
          const termRef = props.firebase.db.doc(
            `users/${authUser.uid}/${dbTerm}/${ids[field].id}`
          );
          batch.set(
            termRef,
            {
              balance: firebase.firestore.FieldValue.increment(
                props.transaction.amount
              ),
              credit: firebase.firestore.FieldValue.increment(
                doubleEntryTransaction.credit
              ),
              debit: firebase.firestore.FieldValue.increment(
                doubleEntryTransaction.debit
              ),
              loan: firebase.firestore.FieldValue.increment(
                doubleEntryTransaction.loan
              ),
            },
            { merge: true }
          );
          form[field] = name;
        }
      }
    }
    const meRef = props.firebase.db.doc(
      `users/${authUser.uid}/counterparties/${authUser.uid}`
    );
    batch.set(
      meRef,
      {
        balance: firebase.firestore.FieldValue.increment(
          -props.transaction.amount
        ),
        credit: firebase.firestore.FieldValue.increment(myTransaction.credit),
        debit: firebase.firestore.FieldValue.increment(myTransaction.debit),
        loan: firebase.firestore.FieldValue.increment(myTransaction.loan),
      },
      { merge: true }
    );
    const meTransRef = props.firebase.db.doc(
      `users/${authUser.uid}/counterparties/${authUser.uid}/transactions/${form.dateRef}`
    );
    batch.set(
      meTransRef,
      { [props.transaction.tid]: firebase.firestore.FieldValue.delete() },
      { merge: true }
    );

    const transactionRef = props.firebase.db.doc(
      `users/${authUser.uid}/accounts/${props.loadedAccount.aid}/allTransactions/${props.transaction.tid}`
    );
    batch.set(transactionRef, { ...form, reconciled: false }, { merge: true });
    const transDateRef = props.firebase.db.doc(
      `users/${authUser.uid}/accounts/${props.loadedAccount.aid}/transactionDates/${form.dateRef}`
    );
    batch.set(
      transDateRef,
      { [props.transaction.tid]: { ...form, reconciled: false } },
      { merge: true }
    );
    const accountRef = props.firebase.db.doc(
      `users/${authUser.uid}/accounts/${props.loadedAccount.aid}`
    );
    batch.set(
      accountRef,
      { pendingTransactions: firebase.firestore.FieldValue.increment(1) },
      { merge: true }
    );
    try {
      batch.commit();
      handleClose();
    } catch (error) {
      console.error("Error explaining transaction: ", error);
    }
  }
  return (
    <Box sx={{ mt: 4 }}>
      {form && (
        <Box>
          <Typography>{form.counterParty}</Typography>
          <Typography>{form.category}</Typography>
          <Typography>{form.type}</Typography>
          <Typography>{form.reference}</Typography>

          <Typography>{form.project}</Typography>

          <Box sx={{ display: "flex", justifyContent: "space-between", mt: 2 }}>
            <Button
              onClick={() => unExplainTransaction()}
              variant="contained"
              color="secondary"
            >
              Remove Explaination
            </Button>
            <Button onClick={() => handleClose()}>Cancel</Button>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default withFirebase(ExplainedTransaction);
