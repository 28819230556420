import React, { useState, useEffect } from "react";
import { Typography, Box, Button, IconButton, Stack } from "@mui/material";
import { withFirebase } from "../../Firebase";
import firebase from "firebase/compat/app";
import { useAuth } from "../../../providers";
import CardSection from "../../Layout/CardSection";
import { DateToday, getDay } from "../../../constants/functions";
import TransactionList from "./transactionList";
import { recalculateBalances } from "../functions";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import DeleteIcon from "@mui/icons-material/Delete";
import { DatePicker } from "@mui/x-date-pickers";

const Transactions = (props) => {
  const { authUser } = useAuth();
  const today = DateToday();
  const oneMonthAgo = DateToday(-30);
  const [transactionMonth, setTransactionMonth] = useState(today);

  const [transactions, setTransactions] = useState(false);

  const [tab, setTab] = useState("all");

  const [paymentCategory, setPaymentCategory] = useState(false);
  const [transactionTypes, setTransactionTypes] = useState(false);
  const [projects, setProjects] = useState(false);
  const [counterParties, setCounterParties] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [datePickerOpen, setDatePickerOpen] = useState(false);

  useEffect(() => {
    const unsubscribe = props.firebase.db
      .doc(`users/${authUser.uid}/indexes/paymentCategories`)
      .onSnapshot((snapshot) => {
        if (snapshot.exists) {
          let categories = {};
          Object.entries(snapshot.data()).map(([key, category]) => {
            categories[key] = { label: category.title, ...category };
          });
          setPaymentCategory(categories);
        } else {
          setPaymentCategory(null);
        }
      });
    return () => unsubscribe();
  }, []);
  useEffect(() => {
    const unsubscribe = props.firebase.db
      .doc(`users/${authUser.uid}/indexes/transactionTypes`)
      .onSnapshot((snapshot) => {
        if (snapshot.exists) {
          let types = {};
          Object.entries(snapshot.data()).map(([key, type]) => {
            types[key] = { label: type.title, ...type };
          });

          setTransactionTypes(types);
        } else {
          setTransactionTypes(null);
        }
      });
    return () => unsubscribe();
  }, []);

  useEffect(() => {
    const unsubscribe = props.firebase.db
      .doc(`users/${authUser.uid}/indexes/projects`)
      .onSnapshot((snapshot) => {
        let proj = {};
        if (snapshot.exists) {
          Object.entries(snapshot.data()).map(([key, project]) => {
            proj[key] = { label: project.title, ...project };
          });
        } else {
          proj = null;
        }
        setProjects(proj);
      });
    return () => unsubscribe();
  }, []);

  useEffect(() => {
    const unsubscribe = props.firebase.db
      .doc(`users/${authUser.uid}/indexes/counterparties`)
      .onSnapshot((snapshot) => {
        if (snapshot.exists) {
          let parties = {};
          Object.entries(snapshot.data()).map(([key, type]) => {
            parties[key] = { label: type.title, ...type };
          });

          setCounterParties(parties);
        } else {
          setCounterParties(null);
        }
      });
    return () => unsubscribe();
  }, []);

  useEffect(() => {
    if (props.loadedAccount) {
      let query = props.firebase.db.collection(
        `users/${authUser.uid}/accounts/${props.loadedAccount.aid}/transactionDates`
      );
      let month = ("0" + transactionMonth.month).slice(-2);

      if (tab === "all") {
        query = query
          .where(
            firebase.firestore.FieldPath.documentId(),
            ">=",
            `${transactionMonth.year}${month}01`
          )
          .where(
            firebase.firestore.FieldPath.documentId(),
            "<=",
            `${transactionMonth.year}${month}32`
          );
      }
      const unsubscribe = query.onSnapshot((snapshot) => {
        if (!snapshot.empty) {
          let data = flattenTransactions(snapshot);
          let startBalance = 0.0;
          if (data[0]?.dayBalances !== undefined) {
            startBalance = data[0].dayBalances.start;
          }
          data = data.map((transaction) => {
            transaction.balance = startBalance + transaction.amount;
            startBalance = transaction.balance;
            return transaction;
          });
          setTransactions(data);
        } else {
          setTransactions([]);
        }
      });
      return () => unsubscribe();
    }
  }, [
    props.loadedAccount,
    transactionMonth,
    counterParties,
    projects,
    transactionTypes,
    paymentCategory,
  ]);

  function flattenTransactions(snapshot) {
    let data = [];
    snapshot.forEach((doc) => {
      Object.entries(doc.data()).forEach(([key, value]) => {
        if (key !== "balances") {
          let category = value.category;
          let counterParty = value.counterParty;
          let project = value.project;
          let type = value.type;
          if (paymentCategory && paymentCategory[category]) {
            category = paymentCategory[category].title;
          }
          if (counterParties && counterParties[counterParty]) {
            counterParty = counterParties[counterParty].title;
          }
          if (projects && projects[project]) {
            project = projects[project].title;
          }
          if (transactionTypes && transactionTypes[type]) {
            type = transactionTypes[type].title;
          }

          data.push({
            ...value,
            category,
            counterParty,
            project,
            type,
            tid: key,
            dateRef: doc.id,
            dayBalances: doc.data().balances,
          });
        }
      });
    });

    const sortedTransactions = [...data].sort(sortByDateRefAndOrder);

    return sortedTransactions;
  }

  function sortByDateRefAndOrder(a, b) {
    const dateRefComparison = a.dateRef.localeCompare(b.dateRef);
    if (dateRefComparison !== 0) {
      return dateRefComparison;
    }
    return a.order - b.order;
  }

  async function processBalances(startDate) {
    try {
      await recalculateBalances(
        authUser.uid,
        props.loadedAccount.aid,
        startDate
      );
    } catch (err) {
      console.error(err, "Error recalculating balances");
    }
  }
  async function scroll(direction) {
    setTransactions(false);
    let days = new Date(
      transactionMonth.year,
      transactionMonth.month,
      0
    ).getDate();
    if (direction < 0) {
      days = -1 * days;
    }
    var date = new Date(transactionMonth.fullDate);
    date.setDate(date.getDate() + days);
    setTransactionMonth(getDay(date));
  }
  async function deleteLineItems() {
    const items = selectedRows.sort((a, b) => a.dateRef - b.dateRef);
    const prevDoc = await props.firebase.db
      .collection(
        `users/${authUser.uid}/accounts/${props.loadedAccount.aid}/transactionDates`
      )
      .where(
        firebase.firestore.FieldPath.documentId(),
        "<",
        `${items[0].dateRef}`
      )
      .orderBy(firebase.firestore.FieldPath.documentId(), "desc")
      .limit(1)
      .get();
    let prevDateRef = null;
    if (!prevDoc.empty) {
      prevDateRef = prevDoc.docs[0].id;
    }
    const batch = props.firebase.db.batch();
    items.forEach((item) => {
      const ref = props.firebase.db.doc(
        `users/${authUser.uid}/accounts/${props.loadedAccount.aid}/transactionDates/${item.dateRef}`
      );
      batch.update(ref, {
        [item.tid]: firebase.firestore.FieldValue.delete(),
      });
      const transRef = props.firebase.db.doc(
        `users/${authUser.uid}/accounts/${props.loadedAccount.aid}/allTransactions/${item.tid}`
      );
      batch.delete(transRef);
    });
    const accountRef = props.firebase.db.doc(
      `users/${authUser.uid}/accounts/${props.loadedAccount.aid}`
    );
    batch.set(
      accountRef,
      {
        pendingTransactions: firebase.firestore.FieldValue.increment(
          -items.length
        ),
      },
      { merge: true }
    );
    try {
      await batch.commit();
      setSelectedRows([]);
    } catch (err) {
      console.error(err, "Error deleting line items");
    }
    try {
      await processBalances(prevDateRef);
    } catch (err) {
      console.error(err, "Error recalculating balances");
    }
  }

  function openDate() {
    setDatePickerOpen(true);
  }

  function loadDate(newValue) {
    const date = new Date(newValue);
    setTransactionMonth(getDay(date));
    setDatePickerOpen(false);
  }

  return (
    <CardSection sx={{ mt: 2 }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignContent: "center",
        }}
      >
        <Typography variant="h5">Transactions</Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <IconButton onClick={() => scroll(-1)}>
            <ArrowBackIosIcon />
          </IconButton>
          {datePickerOpen ? (
            <DatePicker
              views={["month", "year"]}
              onChange={(newValue) => loadDate(newValue)}
            />
          ) : (
            <Typography variant="body2" onClick={() => openDate()}>
              {`${transactionMonth.monthName} ${transactionMonth.year}`}
            </Typography>
          )}

          <IconButton onClick={() => scroll(1)}>
            <ArrowForwardIosIcon />
          </IconButton>
        </Box>
        {selectedRows.length === 0 ? (
          <Button onClick={() => processBalances()} variant="outlined">
            Recalculate Balances
          </Button>
        ) : (
          <Stack direction="row" spacing={2}>
            <IconButton aria-label="delete" onClick={() => deleteLineItems()}>
              <DeleteIcon />
            </IconButton>
          </Stack>
        )}
      </Box>
      <TransactionList
        transactions={transactions}
        loadedAccount={props.loadedAccount}
        setSelectedRows={setSelectedRows}
        transactionMonth={transactionMonth}
      />
    </CardSection>
  );
};

export default withFirebase(Transactions);
