import {
  createBatch,
  increment,
  newTermRef,
  termTransactionDateRef,
  doubleEntryTransactionDateRef,
  transactionRef,
  transDateRef,
  termRef,
  doubleEntryMeRef,
  accountRef,
  deleteField,
} from "../../../../firebaseRefs";
import firebase from "firebase/compat/app";
import { findIdByTitle } from "../../functions";
import { transactionFields } from "../../../../constants/transactionFields";
import { returnDbTerm } from "../../../../helperFunctions/returnDbTerm";
import { returnTermArray } from "../../../../helperFunctions/returnTermArray";
import { explanationCalculations } from "../../../../helperFunctions/explanationCalculations";

export async function explainNonTransferTransaction(
  uid,
  aid,
  transaction,
  form,
  toggle
) {
  let calcs = explanationCalculations(aid, transaction, form, toggle);
  let doubleEntryTransaction = calcs.doubleEntryTransaction;
  let myTransaction = calcs.myTransaction;

  const batch = createBatch();

  for (const field of transactionFields) {
    let dbTerm = returnDbTerm(field);
    let termArray = await returnTermArray(uid, dbTerm);
    if (
      form[field] !== "" &&
      form[field] !== null &&
      form[field] !== undefined
    ) {
      let id = await findIdByTitle(termArray, form[field]);
      // console.log(id, "ID");
      if (!id) {
        id = firebase.firestore().collection(`users/${uid}/${dbTerm}`).doc().id;
        if (
          field === "counterParty" ||
          field === "project" ||
          field === "assets" ||
          field === "category" ||
          field === "type"
        ) {
          batch.set(
            newTermRef(uid, dbTerm, id),
            {
              title: form[field],
              balance: 0,
              credit: 0,
              debit: 0,
              loan: 0,
              favourite: false,
            },
            { merge: true }
          );
        }
        batch.set(
          firebase.firestore().doc(`users/${uid}/indexes/${dbTerm}`),
          { [id]: { title: form[field], order: 999 } },
          { merge: true }
        );
      }
      if (field === "counterParty") {
        const change = toggle
          ? { [transaction.tid]: doubleEntryTransaction }
          : { [transaction.tid]: deleteField() };

        batch.set(
          termTransactionDateRef(uid, dbTerm, id, form.dateRef),
          change,
          { merge: true }
        );

        batch.set(
          termRef(uid, dbTerm, id),
          {
            balance: increment(
              toggle ? -transaction.amount : transaction.amount
            ),
            credit: increment(doubleEntryTransaction.credit),
            debit: increment(doubleEntryTransaction.debit),
            loan: increment(doubleEntryTransaction.loan),
          },
          { merge: true }
        );
      } else if (
        field === "project" ||
        field === "assets" ||
        field === "category" ||
        field === "type"
      ) {
        const change = toggle
          ? { [transaction.tid]: myTransaction }
          : { [transaction.tid]: deleteField() };
        batch.set(
          termTransactionDateRef(uid, dbTerm, id, form.dateRef),
          change,
          { merge: true }
        );

        batch.set(
          termRef(uid, dbTerm, id),
          {
            balance: increment(
              toggle ? transaction.amount : -transaction.amount
            ),
            credit: increment(myTransaction.credit),
            debit: increment(myTransaction.debit),
            loan: increment(myTransaction.loan),
          },
          { merge: true }
        );
      }
    }
  }

  batch.set(
    doubleEntryMeRef(uid),
    {
      balance: increment(toggle ? transaction.amount : -transaction.amount),
      credit: increment(myTransaction.credit),
      debit: increment(myTransaction.debit),
      loan: increment(myTransaction.loan),
    },
    { merge: true }
  );
  const doubleEntryChange = toggle
    ? { [transaction.tid]: myTransaction }
    : { [transaction.tid]: deleteField() };
  batch.set(
    doubleEntryTransactionDateRef(uid, form.dateRef),
    doubleEntryChange,
    { merge: true }
  );

  batch.set(
    transactionRef(uid, aid, transaction.tid),
    { ...form, reconciled: toggle },
    { merge: true }
  );

  batch.set(
    transDateRef(uid, aid, form.dateRef),
    { [transaction.tid]: { ...form, reconciled: toggle } },
    { merge: true }
  );

  batch.set(
    accountRef(uid, aid),
    { pendingTransactions: toggle ? increment(-1) : increment(1) },
    { merge: true }
  );

  try {
    batch.commit();
    return;
  } catch (error) {
    console.error("Error explaining transaction: ", error);
  }
}
