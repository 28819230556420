import React, { useEffect, useState } from "react";
import { withFirebase } from "../Firebase";
import {
  Box,
  Button,
  List,
  ListItem,
  TextField,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { useAuth } from "../../providers";
import CardSection from "../Layout/CardSection";
import LoadingThrobber from "../Layout/LoadingThrobber";
import { InfoRounded } from "@mui/icons-material";
import { useTheme } from "@mui/material/styles";
import { defaultPaymentCategories } from "../../constants/data";

const PaymentCategories = (props) => {
  const { authUser } = useAuth();
  const theme = useTheme();
  const [newPaymentType, setNewPaymentType] = useState("");
  const [paymentCategories, setPaymentCategories] = useState(false);

  useEffect(() => {
    const unsubscribe = props.firebase.db
      .doc(`users/${authUser.uid}/indexes/paymentCategories`)
      .onSnapshot((snapshot) => {
        if (snapshot.exists) {
          let categories = [];
          Object.entries(snapshot.data()).map(([key, type]) => {
            categories.push({ pcid: key, ...type });
          });
          setPaymentCategories(categories);
        } else {
          setPaymentCategories([]);
        }
      });
    return () => unsubscribe();
  }, []);
  async function saveNew() {
    try {
      const batch = props.firebase.db.batch();
      const indexRef = props.firebase.db.doc(
        `users/${authUser.uid}/indexes/paymentCategories`
      );
      const typeRef = props.firebase.db
        .collection(`users/${authUser.uid}/paymentCategories`)
        .doc();
      // batch.set(typeRef, {}, { merge: true });
      batch.set(
        indexRef,
        { [typeRef.id]: { title: newPaymentType, order: 999 } },
        { merge: true }
      );
      await batch.commit();
    } catch (error) {
      console.warn(error, "ERROR SAVING NEW PAYMENT CATEGORY");
    }
  }

  const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "#f5f5f9",
      color: "rgba(0, 0, 0, 0.87)",
      maxWidth: 300,
      fontSize: theme.typography.pxToRem(12),
      border: "1px solid #dadde9",
    },
  }));

  async function saveDefault() {
    let existingTypes = [];
    console.log(paymentCategories);
    if (paymentCategories.length !== 0) {
      paymentCategories.forEach((type) => {
        existingTypes.push(type.title);
      });
    }
    let newTypes = {};
    defaultPaymentCategories.forEach((type, key) => {
      console.log(type, key);
      if (!existingTypes.includes(type)) {
        const typeRef = props.firebase.db
          .collection(`users/${authUser.uid}/paymentCategories`)
          .doc();
        newTypes[typeRef.id] = { title: type, order: key };
      }
    });
    try {
      props.firebase.db
        .doc(`users/${authUser.uid}/indexes/paymentCategories`)
        .set(newTypes, { merge: true });
    } catch (error) {
      console.warn(error, "ERROR SAVING DEFAULT PAYMENT CATEGORY");
    }
  }

  return (
    <CardSection>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Typography variant="h2" gutterBottom>
          Payment Categories
        </Typography>
        <HtmlTooltip
          title={
            <React.Fragment>
              <Typography color="inherit">
                Payment Categories help with tracking and reporting. We
                recommend starting with basic types:
              </Typography>
              <Typography color="inherit" sx={{ mt: 1 }}>
                {defaultPaymentCategories.map((entry, key) => (
                  <React.Fragment key={key}>{`${entry}, `}</React.Fragment>
                ))}
              </Typography>
              <Button
                sx={{ mt: 1, mb: 1 }}
                onClick={() => saveDefault()}
                fullWidth
                variant="contained"
              >
                Add Default Payment Categories
              </Button>
            </React.Fragment>
          }
        >
          <InfoRounded sx={{ color: theme.palette.text.disabled }} />
        </HtmlTooltip>
      </Box>
      {paymentCategories && paymentCategories.length !== 0 ? (
        <List dense>
          {paymentCategories.map((type) => (
            <ListItem key={type.pcid}>{type.title}</ListItem>
          ))}
        </List>
      ) : paymentCategories && paymentCategories.length === 0 ? (
        <Typography gutterBottom>No Payment Categories</Typography>
      ) : (
        <LoadingThrobber />
      )}
      <Box sx={{ mt: 3 }}>
        <TextField
          size="small"
          fullWidth
          label="Payment Category"
          value={newPaymentType}
          onChange={(e) => setNewPaymentType(e.target.value)}
        />
        <Button
          fullWidth
          variant="outlined"
          sx={{ mt: 1 }}
          onClick={() => saveNew()}
        >
          Add
        </Button>
      </Box>
    </CardSection>
  );
};

export default withFirebase(PaymentCategories);
