import React from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';

import AuthUserContext from './authUserContext';
import { withFirebase } from '../Firebase';
import * as ROUTES from '../../constants/routes';

const withAuthorization = (condition) => (Component) => {
   class WithAuthorization extends React.Component {
      componentDidMount() {
         console.log(this.firebase, 'FIREBASE')
         this.listener = this.props.firebase.onAuthUserListener(
            (authUser) => {
               if (!condition(authUser)) {
                  this.props.history.push(ROUTES.PERMISSIONDENIED);
               }
            },
            () => {
               this.props.history.push(ROUTES.SIGN_IN);
            },
         );
      }

      componentWillUnmount() {
         this.listener();
      }

      render() {
         return (
            <AuthUserContext.Consumer>
               {(authUser) =>
                  condition(authUser) ? <Component {...this.props} /> : null
               }
            </AuthUserContext.Consumer>
         );
      }
   }

   return compose(withRouter, withFirebase)(WithAuthorization);
};

export default withAuthorization;
