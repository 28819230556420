import * as React from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useAuth } from "../../../providers";
import { Box, Divider, Modal } from "@mui/material";
import NewAccountForm from "../../Accounts/newAccountForm";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  backgroundColor: "white",
  boxShadow: 24,
  p: 4,
};

export default function AccountFilter(props) {
  const { authUser } = useAuth();
  const [name, setName] = React.useState(`Accounts`);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openModal, setOpenModal] = React.useState(false);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  React.useEffect(() => {
    if (authUser.accounts && authUser.accounts.length > 0) {
      setName(authUser.accounts[0].name);
      props.setAccount(authUser.accounts[0].aid);
    }
  }, [props.owner]);

  const handleModalOpen = () => setOpenModal(true);
  const handleModalClose = () => setOpenModal(false);

  return (
    <div>
      <Modal open={openModal} onClose={handleModalClose}>
        <Box sx={style}>
          <NewAccountForm setOpen={setOpenModal} />
        </Box>
      </Modal>

      <Button
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        endIcon={open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
      >
        {name}
      </Button>

      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {authUser.accounts &&
          authUser.accounts.length > 0 &&
          authUser.accounts.map((value, key) => (
            <MenuItem
              key={key}
              onClick={() => {
                props.setAccount(value.aid);
                setName(value.name);
                handleClose();
              }}
            >
              {value.name}
            </MenuItem>
          ))}
        <Divider />
        <MenuItem
          onClick={() => {
            handleModalOpen();
            handleClose();
          }}
        >
          Add New
        </MenuItem>
      </Menu>
    </div>
  );
}
