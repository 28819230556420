export const isDateInvalid = (string) => {
  let dateString = string;
  let dateParts = dateString.split("/");

  // Ensure the year is four characters
  if (dateParts[2].length === 2) {
    dateParts[2] = "20" + dateParts[2];
  }

  // Create a date object
  let date = new Date(`${dateParts[2]}-${dateParts[1]}-${dateParts[0]}`);
  console.log(date);
  // Check if the date is valid
  console.log(
    date.getFullYear() === parseInt(dateParts[2]),
    date.getMonth() + 1 === parseInt(dateParts[1]),
    date.getDate() === parseInt(dateParts[0])
  );
  if (
    date &&
    date.getFullYear() === parseInt(dateParts[2]) &&
    date.getMonth() + 1 === parseInt(dateParts[1]) &&
    date.getDate() === parseInt(dateParts[0])
  ) {
    return false;
  } else {
    return true;
  }
};
