// user management
export const SIGN_UP = "/signup";
export const SIGN_IN = "/signin";
export const LOGOUT = "/logout";
export const AUTH = "/auth";
export const LOGGEDINAUTH = "/auth?redirect=settings";
export const PASSWORD_FORGET = "/pw-forget";
export const PASSWORD_SET = "/setpassword";

// main app routes
export const DASHBOARD = "/";
export const ACCOUNTS = "/accounts";
export const ASSETS = "/assets";
export const SETTINGS = "/settings";
export const PROFILE = "/profile";
export const ENTITIES = "/entities";

// protected route menegement
export const PERMISSIONDENIED = "/403";

// admin routes
export const ADMIN = "/admin";

// help and information routes
export const SUPPORT = "/support";

// alt app routes
export const MSTEAMSAUTH = "/msTeamsAuth";
