// import { de } from 'date-fns/locale';
import app from 'firebase/compat/app';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/functions';
import 'firebase/compat/storage';

const config = {
   apiKey: "AIzaSyD5ba5452iSs6dUqqT-lERHSwj5iJN4SNY",
   authDomain: "personalfinance-db865.web.app",
   projectId: "personalfinance-db865",
   storageBucket: "personalfinance-db865.appspot.com",
   messagingSenderId: "299176350155",
   appId: "1:299176350155:web:c64bd8b849f891fe8ce64e",
   measurementId: "G-3DQSTRF801",
      
   // authDomain: 'http://127.0.0.1:4000/auth',

   // databaseURL: 'personalfinance-db865.firebaseapp.com', //"http://localhost:8080/?ns=intheoffice-979c4",
   // databaseURL: '127.0.0.1:8080',
};

class Firebase {
   constructor() {
      app.initializeApp(config);

      this.auth = app.auth();
      this.db = app.firestore();
      this.functions = app.functions();
      this.storage = app.storage();
      this.microsoftProvider = new app.auth.OAuthProvider('microsoft.com').addScope(
         'calendars.read',
      );
   }

   // *** AUth API ***
   doCreateUserWithEmailAndPassword = (email, password) =>
      this.auth.createUserWithEmailAndPassword(email, password);

   doSignInWithEmailAndPassword = (email, password) =>
      this.auth.signInWithEmailAndPassword(email, password);

   doSignInWithMicrosoft = () => this.auth.signInWithRedirect(this.microsoftProvider);
   doSignInWithMicrosoftMSTeams = () => this.auth.signInWithPopup(this.microsoftProvider);

   getMicrosoftCreds = () =>
      this.auth
         .getRedirectResult()
         .then(function (result) {
            return result;
         })
         .catch(function (error) {
            console.log(error, 'from firebase');
            return error;
         });

   // getMicrosoftCreds = () => this.auth.getRedirectResult()
   // .then((result) => {
   //   console.log(result, 'reditect result');
   // })
   // .catch((error) => {
   //   console.log(error, 'error');
   //   // Handle error.
   // });
   accountExistsDifferentCreds = (email) => this.auth.fetchSignInMethodsForEmail(email);

   doSignOut = () =>
      this.auth.signOut().then(() => {
         window.location.reload(false);
      });

   doPasswordReset = (email) => this.auth.sendPasswordResetEmail(email);

   doPasswordUpdate = (password) => this.auth.currentUser.updatePassword(password);

   doSendEmailVerification = () =>
      this.auth.currentUser.sendEmailVerification({
         url: 'https://app.mycashdash.com',
      });

   // *** custom user management *** //
   doCreateUserWithEmail = (email) => this.auth.createUser(email);

   currentFirebaseUser = () => this.auth.currentUser;

   // *** Merge Auth and DB User API *** //
   onAuthUserListener = (next, fallback) =>
      this.auth.onAuthStateChanged((authUser) => {
         if (authUser) {
            this.user(authUser.uid)
               .get()
               .then((snapshot) => {
                  const dbUser = snapshot.data();
                  //might not need the if statement if saving to the DB starts to work.
                  if (dbUser) {
                     // default empty roles
                     if (!dbUser.roles) {
                        dbUser.roles = {};
                     }

                     // merge auth and db user
                     authUser = {
                        uid: authUser.uid,
                        email: authUser.email,
                        emailVerified: authUser.emailVerified,
                        providerData: authUser.providerData,
                        ...dbUser,
                     };

                     next(authUser);
                  }
               });
         } else {
            fallback();
         }
      });

   currentUserId = (uid) =>
      this.auth.onAuthStateChanged((authUser) => {
         if (authUser) {
            return authUser.uid;
         }
      });

   logToDb = (path, obj, options = { merge: true }) => {
      // path: string
      // obj = object
      // options = object

      const data = {
         ...obj,
         date: firebase.firestore.FieldValue.serverTimestamp(),
      };

      this.db
         .doc(path)
         .set({ [new Date().toISOString().split('T')[1]]: { ...data } }, { ...options });
   };
   user = (uid) => this.db.collection('users').doc(`${uid}`);
  
}

export default Firebase;
