import { Box, Typography } from "@mui/material";
import React from "react";
import PaymentTypes from "./paymentTypes";
import TransactionTypes from "./transactionTypes";
import ResetAccount from "./resetAccount";

const Main = (props) => {
  return (
    <Box sx={{ m: 2 }}>
      <Typography variant="h1" gutterBottom>
        Settings
      </Typography>
      <Box sx={{ display: "flex", gap: 2 }}>
        <PaymentTypes />
        <TransactionTypes />
        <ResetAccount />
      </Box>
    </Box>
  );
};

export default Main;
