import React, { useState } from "react";
import { Autocomplete, Box, TextField } from "@mui/material";
import SwapHorizIcon from "@mui/icons-material/SwapHoriz";
import PointOfSaleIcon from "@mui/icons-material/PointOfSale";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import CardGiftcardIcon from "@mui/icons-material/CardGiftcard";
import RequestQuoteIcon from "@mui/icons-material/RequestQuote";

const ExplainAuto = ({ options, type, typeLabel, setForm, form }) => {
  const [projectHelperText, setProjectHelperText] = useState("");

  function formatOptions(option) {
    if (type === "category") {
      switch (option.order) {
        case 1:
          return (
            <>
              <AccountBalanceIcon fontSize="small" sx={{ mr: 1 }} />
              {option.title}
            </>
          );
        case 2:
          return (
            <>
              <PointOfSaleIcon fontSize="small" sx={{ mr: 1 }} />
              {option.title}
            </>
          );
        case 3:
          return (
            <>
              <RequestQuoteIcon fontSize="small" sx={{ mr: 1 }} />
              {option.title}
            </>
          );
        case 4:
          return (
            <>
              <CardGiftcardIcon fontSize="small" sx={{ mr: 1 }} />
              {option.title}
            </>
          );
        case 5:
          return (
            <>
              <SwapHorizIcon fontSize="small" sx={{ mr: 1 }} />
              {option.title}
            </>
          );
        default:
          return <Box sx={{ ml: 4 }}>{option.title}</Box>;
      }
    } else {
      return option.title;
    }
  }

  return (
    <Autocomplete
      disablePortal
      id={typeLabel}
      options={options}
      sx={{ width: 250 }}
      value={form[type]}
      renderOption={(props, option) => (
        <Box
          component="li"
          sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
          {...props}
        >
          {formatOptions(option)}
        </Box>
      )}
      renderInput={(params) => (
        <>
          <TextField
            {...params}
            size="small"
            label={typeLabel}
            sx={{ mt: 1 }}
            helperText={projectHelperText}
          />
        </>
      )}
      onInputChange={(event, value) => {
        setForm({ ...form, [type]: value });
        if (
          options.some(
            (obj) => obj.title.toLowerCase() === value.toLowerCase()
          ) &&
          value !== ""
        ) {
          //   setProjectHelperText("New payment category");
          // } else {
          //   setProjectHelperText("");
        }
      }}
      freeSolo={type !== "account" ? true : false}
      includeInputInList
    />
  );
};
export default ExplainAuto;
