import paymentTypes from "../components/Settings/paymentTypes";

export const personalAccounts = [
  {
    id: 1,
    order: 1,
    name: "Checking",
    type: "personal",
    expenseBalance: 0,
    liabilityBalance: 500,
  },
  {
    id: 2,
    order: 2,
    name: "Savings",
    type: "savings",
    expenseBalance: 500,
    liabilityBalance: 0,
  },
  {
    id: 3,
    order: 3,
    name: "Credit Card",
    type: "credit",
    expenseBalance: 0,
    liabilityBalance: 500,
  },
];

export const otherAccounts = [
  {
    id: 1,
    order: 1,
    name: "Checking",
    type: "Other",
    favorite: true,
    expenseBalance: 0,
    liabilityBalance: 500,
  },
  {
    id: 2,
    order: 2,
    name: "Savings",
    type: "Other",
    favorite: true,
    expenseBalance: 500,
    liabilityBalance: 0,
  },
  {
    id: 3,
    order: 3,
    name: "Credit Card",
    type: "Other",
    favorite: false,
    expenseBalance: 0,
    liabilityBalance: 500,
  },
];

// export const structure = {
//   macBgAsoJyRlCY9sIcokpyUqhYy1: {
//     automations: {
//       descriptions: {
//         name: aid,
//       },
//       accounts: {
//         name: aid,
//       },
//       //match the name for the aid
//     },
//     personalAccounts: {
//       [aid]: {
//         order: 1,
//         name: "Starling Current",
//         type: "current",
//         favourite: true,
//         expenseBalance: 0,
//         liabilityBalance: 500,
//         processingBalance: 400,
//         expenses: {
//           [date]: {
//             [tid]: {
//               amount: 500,
//             },
//           },
//         },
//         liabilities: {},
//         transactions: {
//           [dateRef]: {
//             [transactionOrder]: {
//               tid: [tid],
//               date: [date],
//               description: "",
//               amount: 500,
//               accounts: {
//                 [aid]: "credit",
//                 [aid]: "debit",
//               },
//             },
//           },
//         },
//       },
//     },
//     otherAccounts: {
//       [aid]: {
//         order: 1,
//         name: "Jane",
//         type: "person",
//         favourite: true,
//       },
//     },
//   },
// };

export const defaultPaymentCategories = [
  "Income",
  "Payment",
  "Transfer",
  "Loan",
  "Gift",
  "Bills and Services",
  "General",
  "Shopping",
  "Holidays",
  "Entertainment",
  "Transport",
  "Eating out",
];

export const defaultTransactionTypes = [
  "BACS",
  "Direct Debit",
  "Standing Order",
  "Cheque",
  "ATM",
  "Transfer",
  "Card Payment",
  "Apple Pay",
  "Google Pay",
  "Faster Payment",
  "Subscription",
  "Contactless",
  "Deposit Interest",
];
