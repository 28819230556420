import firebase from "firebase/compat/app";

export function findIdByTitle(arr, searchTitle) {
  console.log(arr, searchTitle, "SEARCH");
  for (const item of arr) {
    console.log(item);
    if (item.title === searchTitle) {
      return item.id;
    }
  }
  return false;
}

export async function recalculateBalances(uid, aid, startDateRef = null) {
  let query = firebase
    .firestore()
    .collection(`users/${uid}/accounts/${aid}/transactionDates`);
  if (startDateRef !== null) {
    query = query.where(
      firebase.firestore.FieldPath.documentId(),
      ">=",
      startDateRef
    );
  }

  const transactionDates = await query.get();
  let a = 0;
  if (transactionDates.size > 400) {
    const chunkSize = 400;
    let chunks = [];
    for (let i = 0; i < transactionDates.docs.length - 1; i += chunkSize) {
      chunks.push(transactionDates.docs.slice(i, i + chunkSize));
    }
    let count = 1;

    for (const chunk of chunks) {
      const data = { docs: chunk };
      try {
        if (a > 0) {
          await recalculateBalancesBatch(
            uid,
            aid,
            data,
            chunks[a - 1][chunks[a - 1].length - 1].id
          );
        } else {
          await recalculateBalancesBatch(uid, aid, data);
        }
        count++;
        a++;
      } catch (err) {
        console.error(err, "Error recalculateBalances");
      }
      // });
    }
    // }
  } else {
    try {
      await recalculateBalancesBatch(uid, aid, transactionDates);
    } catch (err) {
      console.error(err, "Error recalculateBalances");
    }
  }
}

async function recalculateBalancesBatch(
  uid,
  aid,
  transactions,
  prevDate = null
) {
  const batch = firebase.firestore().batch();
  const openingBalance = 0.0;
  let balance = {
    start: parseFloat(openingBalance),
    end: parseFloat(openingBalance),
    difference: 0,
  };

  if (prevDate !== null) {
    const prevDateDoc = await firebase
      .firestore()
      .doc(`users/${uid}/accounts/${aid}/transactionDates/${prevDate}`)
      .get();
    balance.start = parseFloat(prevDateDoc.data().balances.start);
    balance.end = parseFloat(prevDateDoc.data().balances.end);
  }
  transactions.docs.forEach((doc) => {
    let difference = 0;
    Object.entries(doc.data()).forEach(([key, value]) => {
      if (key !== "balances") {
        difference += parseFloat(value.amount);
        balance.end += parseFloat(value.amount);
      }
    });
    balance.difference = difference.toFixed(2);
    balance.start = balance.start.toFixed(2);
    balance.end = balance.end.toFixed(2);
    balance.difference = parseFloat(difference);
    balance.start = parseFloat(balance.start);
    balance.end = parseFloat(balance.end);
    const ref = firebase
      .firestore()
      .doc(`users/${uid}/accounts/${aid}/transactionDates/${doc.id}`);
    batch.set(ref, { balances: balance }, { merge: true });
    balance.start = parseFloat(balance.end);
    balance.difference = 0;
    balance.end = parseFloat(balance.end);
  });
  const accountRef = firebase.firestore().doc(`users/${uid}/accounts/${aid}`);
  batch.set(accountRef, { balance: balance.end }, { merge: true });
  try {
    await batch.commit();
  } catch (error) {
    console.error("Error processing balances: ", error);
  }
}
