import {
  Box,
  List,
  ListItem,
  ListItemText,
  Stack,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import AccountFilter from "../Filters/AccountFilter";
import OwnerFilter from "../Filters/OwnerFilter";
import { withFirebase } from "../Firebase";

const Summary = ({
  firebase,
  setAccount,
  account,
  setOwner,
  owner,
  loadedAccount,
  setLoadedAccount,
}) => {
  useEffect(() => {
    if (owner && account) {
      const unsubscribe = firebase.db
        .doc(`users/${owner.uid}/accounts/${account}`)
        .onSnapshot((snapshot) => {
          if (snapshot.exists) {
            setLoadedAccount({ ...snapshot.data(), aid: snapshot.id });
          } else {
            setLoadedAccount(false);
          }
        });
      return () => unsubscribe();
    }
  }, [owner, account]);
  useEffect(() => {
    if (owner && account) {
      const unsubscribe = firebase.db
        .doc(`users/${owner.uid}/accounts/${account}`)
        .onSnapshot((snapshot) => {
          if (snapshot.exists) {
            setLoadedAccount({ ...snapshot.data(), aid: snapshot.id });
          } else {
            setLoadedAccount(false);
          }
        });
      return () => unsubscribe();
    }
  }, [owner, account]);
  return (
    <Box sx={{ display: "flex", justifyContent: "space-between" }}>
      <Box>
        <Stack direction="row" spacing={2}>
          <OwnerFilter setOwner={setOwner} />
          <AccountFilter setAccount={setAccount} owner={owner} />
        </Stack>
      </Box>
      <Box>
        <Typography>Balance: {loadedAccount.balance}</Typography>
      </Box>
      {/* <List dense>
        <ListItem>
          <ListItemText>Balance: {props.loadedAccount.balance}</ListItemText>
        </ListItem>
        <ListItem>
          <ListItemText>
            Last Transaction Date:{" "}
            {props.loadedAccount.balance !== null
              ? props.loadedAccount.balance
              : "N/A"}
          </ListItemText>
        </ListItem>
        <ListItem>
          <ListItemText>
            Pending Transactions: {props.loadedAccount.pendingTransactions}
          </ListItemText>
        </ListItem>
      </List> */}
    </Box>
  );
};

export default withFirebase(Summary);
