import React, { useEffect, useState } from "react";
import { withFirebase } from "../Firebase";
import {
  Box,
  Button,
  List,
  ListItem,
  TextField,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { useAuth } from "../../providers";
import CardSection from "../Layout/CardSection";
import LoadingThrobber from "../Layout/LoadingThrobber";
import { InfoRounded } from "@mui/icons-material";
import { useTheme } from "@mui/material/styles";
import { defaultTransactionTypes } from "../../constants/data";

const TransactionTypes = (props) => {
  const { authUser } = useAuth();
  const theme = useTheme();
  const [newTransactionType, setNewTransactionType] = useState("");
  const [transactionTypes, setTransactionTypes] = useState(false);

  useEffect(() => {
    const unsubscribe = props.firebase.db
      .doc(`users/${authUser.uid}/indexes/transactionTypes`)
      .onSnapshot((snapshot) => {
        if (snapshot.exists) {
          let transactionTypes = [];
          Object.entries(snapshot.data()).map(([key, type]) => {
            transactionTypes.push({ trid: key, ...type });
          });
          setTransactionTypes(transactionTypes);
        } else {
          setTransactionTypes([]);
        }
      });
    return () => unsubscribe();
  }, []);
  async function saveNew() {
    try {
      const batch = props.firebase.db.batch();
      const indexRef = props.firebase.db.doc(
        `users/${authUser.uid}/indexes/transactionTypes`
      );
      const typeRef = props.firebase.db
        .collection(`users/${authUser.uid}/transactionTypes`)
        .doc();
      // batch.set(typeRef, {}, { merge: true });
      batch.set(
        indexRef,
        { [typeRef.id]: { title: newTransactionType, order: 999 } },
        { merge: true }
      );
      await batch.commit();
    } catch (error) {
      console.warn(error, "ERROR SAVING NEW TRANSACTION TYPE");
    }
  }

  const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "#f5f5f9",
      color: "rgba(0, 0, 0, 0.87)",
      maxWidth: 300,
      fontSize: theme.typography.pxToRem(12),
      border: "1px solid #dadde9",
    },
  }));

  async function saveDefault() {
    let existingTypes = [];
    if (transactionTypes.length !== 0) {
      transactionTypes.forEach((type) => {
        existingTypes.push(type.title);
      });
    }
    let newTypes = {};
    defaultTransactionTypes.forEach((type, key) => {
      if (!existingTypes.includes(type)) {
        const typeRef = props.firebase.db
          .collection(`users/${authUser.uid}/transactionTypes`)
          .doc();
        newTypes[typeRef.id] = { title: type, order: key };
      }
    });
    try {
      props.firebase.db
        .doc(`users/${authUser.uid}/indexes/transactionTypes`)
        .set(newTypes, { merge: true });
    } catch (error) {
      console.warn(error, "ERROR SAVING DEFAULT TRANSACTION TYPES");
    }
  }

  return (
    <CardSection>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Typography variant="h2" gutterBottom>
          Transaction Types
        </Typography>
        <HtmlTooltip
          title={
            <React.Fragment>
              <Typography color="inherit">
                Transaction Types is the way the transaction was made, e.g.
                BACS, or ATM. We recommend adding the following most common:
              </Typography>
              <Typography color="inherit" sx={{ mt: 1 }}>
                {defaultTransactionTypes.map((entry, key) => (
                  <React.Fragment key={key}>{`${entry}, `}</React.Fragment>
                ))}
              </Typography>
              <Button
                sx={{ mt: 1, mb: 1 }}
                onClick={() => saveDefault()}
                fullWidth
                variant="contained"
              >
                Add Default Transaction Types
              </Button>
            </React.Fragment>
          }
        >
          <InfoRounded sx={{ color: theme.palette.text.disabled }} />
        </HtmlTooltip>
      </Box>
      {transactionTypes && transactionTypes.length !== 0 ? (
        <List dense>
          {transactionTypes.map((type) => (
            <ListItem key={type.trid}>{type.title}</ListItem>
          ))}
        </List>
      ) : transactionTypes && transactionTypes.length === 0 ? (
        <Typography gutterBottom>No Transaction Types</Typography>
      ) : (
        <LoadingThrobber />
      )}
      <Box sx={{ mt: 3 }}>
        <TextField
          size="small"
          fullWidth
          label="Transaction Type"
          value={newTransactionType}
          onChange={(e) => setNewTransactionType(e.target.value)}
        />
        <Button
          fullWidth
          variant="outlined"
          sx={{ mt: 1 }}
          onClick={() => saveNew()}
        >
          Add
        </Button>
      </Box>
    </CardSection>
  );
};

export default withFirebase(TransactionTypes);
