export const explanationCalculations = (aid, transaction, form, toggle) => {
  let doubleEntryTransaction = {
    credit: 0,
    debit: 0,
    loan: 0,
    account: aid,
  };
  let myTransaction = { credit: 0, debit: 0, loan: 0, account: aid };

  if (transaction.amount < 0) {
    myTransaction.debit = toggle ? -transaction.amount : transaction.amount;
    doubleEntryTransaction.credit = toggle
      ? -transaction.amount
      : transaction.amount;
  } else if (transaction.amount > 0) {
    myTransaction.credit = toggle ? transaction.amount : -transaction.amount;
    doubleEntryTransaction.debit = toggle
      ? transaction.amount
      : -transaction.amount;
  }
  if (form.category === "Loan") {
    doubleEntryTransaction.loan = toggle
      ? -transaction.amount
      : transaction.amount;
    myTransaction.loan = toggle ? transaction.amount : -transaction.amount;
  }
  let calcs = { myTransaction, doubleEntryTransaction };
  return calcs;
};
