import React, { useState, useEffect } from "react";
import { DataGrid, GridToolbar, GridToolbarExport } from "@mui/x-data-grid";
import { withFirebase } from "../Firebase";
import {
  Box,
  Button,
  FormControl,
  Input,
  InputLabel,
  List,
  ListItem,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import Papa from "papaparse";
import { useAuth } from "../../providers";
import { recalculateBalances } from "./functions";
import { isDateInvalid } from "../../helperFunctions/isDateInvalid";
import LoadingThrobber from "../Layout/LoadingThrobber";
import { upload } from "@testing-library/user-event/dist/upload";
import { accountRef } from "../../firebaseRefs";

const defaultFieldValues = {
  date: {
    title: "Date",
    value: null,
    required: true,
  },
  counterParty: {
    title: "Counter Party",
    value: null,
    required: true,
  },
  reference: {
    title: "Reference",
    value: null,
    required: false,
  },
  amount: {
    title: "Amount",
    value: null,
    required: true,
  },
  type: {
    title: "Type",
    value: null,
    required: false,
  },
  category: {
    title: "Category",
    value: null,
    required: false,
  },
  transactionId: {
    title: "Transaction Id",
    value: null,
    required: false,
  },
};

const UploadFile = (props) => {
  const { authUser } = useAuth();
  const uid = authUser.uid;
  const aid = props.loadedAccount.aid;
  const [allData, setAllData] = useState(false);
  const [fieldsMapped, setFieldsMapped] = useState(false);
  const [fields, setFields] = useState(defaultFieldValues);
  const [data, setData] = useState([]);
  const [columns, setColumns] = useState([]);
  const [rowSelectionModel, setRowSelectionModel] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [accountDetails, setAccountDetails] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [uploadProblem, setUploadProblem] = useState(false);

  useEffect(() => {
    const unsubscribe = accountRef(uid, aid).onSnapshot((snapshot) => {
      if (snapshot.exists) {
        setAccountDetails({ ...snapshot.data(), id: snapshot.id });
      } else {
        setAccountDetails(false);
      }
    });
    return () => unsubscribe();
  }, []);

  const uploadFile = async (event) => {
    let file = event.target.files[0];
    if (file) {
      await Papa.parse(file, {
        header: true,
        dynamicTyping: true,
        complete: async (results) => {
          setAllData(results);
          let names = { ...defaultFieldValues };
          await new Promise((resolve) => {
            results.meta.fields.forEach((fieldName, index) => {
              if (names.hasOwnProperty([fieldName.toLowerCase()])) {
                names[fieldName.toLowerCase()].value = fieldName;
              }
              if (index === results.meta.fields.length - 1) {
                resolve();
              }
            });
          });
          setFields(names);
        },
      });
    }
  };

  async function proceed() {
    let valid = true;
    setUploadProblem(false);
    Object.values(fields).forEach((field) => {
      if (field.required && !field.value) {
        valid = false;
      }
    });
    if (valid) {
      let i = 0;
      let dataWithMapping = [];
      Promise.all(
        allData.data.map(async (row) => {
          if (
            row[fields.amount.value] !== null &&
            row[fields.amount.value] !== undefined
          ) {
            const docData = await props.firebase.db
              .collection(`users/${uid}/accounts/${aid}/allTransactions`)
              .doc()
              .get();
            let tid = docData.id;
            let data = {};

            let problem = isDateInvalid(row.Date);
            if (problem) {
              setUploadProblem(true);
            }
            Object.entries(fields).forEach(([key, field]) => {
              data[key] = row[field.value] ?? null;
            });
            data.id = i++;

            data.transactionId = tid;

            data.width = 150;
            dataWithMapping.push(data);
          }
        })
      )
        .then(() => {
          setData(dataWithMapping);
          let columnNames = [{ field: "id" }];
          Object.entries(fields).map(([key, field]) =>
            columnNames.push({
              field: key,
              width: 150,
            })
          );
          setColumns(columnNames);
          setFieldsMapped(true);
        })
        .catch((error) => {
          console.error("Cannot get the tid", error);
        });
    } else {
      alert("Please map all required fields");
    }
  }

  const uploadEntries = async () => {
    setProcessing(true);
    let count = rowSelectionModel.length;
    if (count <= 200) {
      await loadEntries(rowSelectionModel);
      await recalculateBalances(uid, aid, data[rowSelectionModel[0]].dateRef);
    } else {
      const chunkSize = 200;
      let chunks = [];
      for (let i = 0; i < rowSelectionModel.length - 1; i += chunkSize) {
        chunks.push(rowSelectionModel.slice(i, i + chunkSize));
      }
      for (const chunk of chunks) {
        await loadEntries(chunk);
        await recalculateBalances(uid, aid, data[chunk[0]].dateRef);
      }
    }
    setProcessing(false);
    props.setOpen(false);
  };

  async function loadEntries(row) {
    const account = await props.firebase.db
      .doc(`users/${uid}/accounts/${aid}`)
      .get();
    let balance = account.data().balance;
    let pendingTransactions = account.data().pendingTransactions;
    const batch = props.firebase.db.batch();
    row.forEach((row) => {
      pendingTransactions++;
      balance += data[row].amount;
      let dateString = data[row].date;
      let dateParts = dateString.split("/");
      if (dateParts[2].length === 2) {
        dateParts[2] = "20" + dateParts[2];
      }
      let dateRef = dateParts[2] + dateParts[1] - 1 + dateParts[0];

      let transRef = props.firebase.db.doc(
        `users/${uid}/accounts/${aid}/allTransactions/${data[row].transactionId}`
      );
      let transaction = {
        ...data[row],
        dateRef,
        order: row,
        reconciled: false,
      };
      delete transaction.width;
      delete transaction.id;
      batch.set(transRef, transaction, { merge: true });
      let transDateRef = props.firebase.db.doc(
        `users/${uid}/accounts/${aid}/transactionDates/${dateRef}`
      );
      let transDate = {
        [data[row].transactionId]: {
          ...data[row],
          dateRef,
          order: row,
          reconciled: false,
        },
      };
      delete transDate[data[row].transactionId].width;
      delete transDate[data[row].transactionId].id;
      batch.set(transDateRef, transDate, { merge: true });
    });
    let accountRef = props.firebase.db.doc(`users/${uid}/accounts/${aid}`);
    batch.set(accountRef, { balance, pendingTransactions }, { merge: true });
    try {
      batch.commit();
    } catch (error) {
      console.error("Error uploading entries", error);
    }
  }

  return (
    <Box sx={{ minHeight: 500, display: "flex", flexDirection: "column" }}>
      {!allData && (
        <Box>
          <Input
            accept="csv /*"
            style={{ display: "none" }}
            id="contained-button-file"
            multiple
            type="file"
            onChange={uploadFile}
          />
          <label htmlFor="contained-button-file">
            <Button
              sx={{
                height: data.length === 0 ? 500 : 0,
                display: data.length === 0 ? "flex" : "none",
                border: "1px dotted black",
              }}
              fullWidth
              component="span"
            >
              Select a File
            </Button>
          </label>
        </Box>
      )}
      {allData && !fieldsMapped && (
        <Box>
          <Typography variant="h5">Map your fields</Typography>
          <List>
            <ListItem sx={{ display: "flex", justifyContent: "space-between" }}>
              <Box sx={{ width: "44%" }}>
                <Typography sx={{ textDecoration: "strong" }}>
                  MyCashDash Field
                </Typography>
              </Box>
              <Box sx={{ width: "4%", ml: "4%", mr: "4%" }}>{`<->`}</Box>
              <Box sx={{ width: "44%" }}>
                <Typography sx={{ textDecoration: "strong" }}>
                  Imported Column
                </Typography>
              </Box>
            </ListItem>
            {Object.entries(fields).map(([key, field]) => {
              if (key !== "transactionId") {
                return (
                  <ListItem
                    key={key}
                    sx={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Box sx={{ width: "44%" }}>{field.title}</Box>
                    <Box sx={{ width: "4%", ml: "4%", mr: "4%" }}>{`<->`}</Box>
                    <Box sx={{ width: "44%" }}>
                      <FormControl fullWidth required={field.required}>
                        <InputLabel id={key}>{field.title}</InputLabel>
                        <Select
                          labelId={key}
                          label={field.title}
                          value={field.value || ""}
                          onChange={(e) => {
                            setFields({
                              ...fields,
                              [key]: { ...field, value: e.target.value },
                            });
                          }}
                        >
                          {allData.meta.fields.map((fieldName, key) => (
                            <MenuItem key={key} value={fieldName}>
                              {fieldName}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Box>
                  </ListItem>
                );
              }
            })}
          </List>
          <Box sx={{ mt: 2 }}>
            <Button
              variant="contained"
              onClick={() => {
                proceed();
              }}
            >
              Proceed
            </Button>
          </Box>
        </Box>
      )}
      {processing ? (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <LoadingThrobber />
          <Typography align="center" variant="h5">
            Do not close this popup
          </Typography>
        </Box>
      ) : (
        <Box>
          {data.length !== 0 && (
            <Box>
              <Box
                sx={{
                  height: 450,
                  width: "100%",
                  flexDirection: "column",
                }}
              >
                <DataGrid
                  rowHeight={35}
                  checkboxSelection
                  onRowSelectionModelChange={(newRowSelectionModel) => {
                    setRowSelectionModel(newRowSelectionModel);
                  }}
                  rowSelectionModel={rowSelectionModel}
                  rows={data}
                  columns={columns}
                />
              </Box>
            </Box>
          )}
          {uploadProblem && (
            <Typography variant="body1" color="error">
              Please check the date format for this upload. they all need to be
              valid dates, in dd/mm/YYYY format, e.g. 20/02/2024.
            </Typography>
          )}
          <Button
            sx={{ mt: 2 }}
            variant="contained"
            onClick={uploadEntries}
            disabled={rowSelectionModel.length == 0 || uploadProblem}
          >
            Upload Entries
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default withFirebase(UploadFile);
