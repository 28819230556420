import React, { useState } from "react";
import {
  List,
  Typography,
  Box,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableContainer,
  TableBody,
} from "@mui/material";

import CarriedForwardBalance from "./carriedForwardBalance";
import LoadingThrobber from "../../Layout/LoadingThrobber";
import LineItem from "./lineItem";

const TransactionList = ({ transactions, ...props }) => {
  console.log(props);
  return (
    <TableContainer sx={{ maxHeight: "70vh" }}>
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell></TableCell>
            <TableCell>Date</TableCell>
            <TableCell></TableCell>
            <TableCell>Description</TableCell>

            {/* <TableCell>Actions</TableCell> */}
            <TableCell>Money In</TableCell>
            <TableCell>Money Out</TableCell>
            <TableCell>Balance</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <CarriedForwardBalance
            transactionMonth={props.transactionMonth}
            loadedAccount={props.loadedAccount}
          />
          {transactions && transactions.length !== 0 ? (
            <>
              {transactions.map((transaction) => (
                <LineItem
                  transaction={transaction}
                  key={transaction.tid}
                  loadedAccount={props.loadedAccount}
                  setSelectedRows={props.setSelectedRows}
                />
              ))}
            </>
          ) : transactions && transactions.length === 0 ? (
            <TableRow>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell>
                <Typography variant="body2">No transactions found</Typography>
              </TableCell>

              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableRow>
          ) : (
            <TableRow>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell>
                <LoadingThrobber />
              </TableCell>

              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
export default TransactionList;
