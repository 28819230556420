var months = [
   'January',
   'February',
   'March',
   'April',
   'May',
   'June',
   'July',
   'August',
   'September',
   'October',
   'November',
   'December',
];
var days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
var shortDay = ['Sun', 'Mon', 'Tue', 'Wed', 'Thur', 'Fri', 'Sat'];

export const getWeekDates = (date) => {
   // TODO: if the day is a sunday then the schedule loads on the next week because the dayNUmiber goes to -1
   var dayNumber = date.weekDay;
   var d = new Date(date.year, date.month, date.day);
   if (dayNumber === 0) {
      d.setDate(d.getDate() - 7);
   } else {
      d.setDate(d.getDate() - dayNumber);
   }
   var weekDates = [];
   for (var i = 0; i < 7; i++) {
      weekDates[i] = {
         day: d.getDate(),
         dayName: days[d.getDay()],
         shortDayName: shortDay[d.getDay()],
         month: d.getMonth(),
         monthName: months[d.getMonth()],
         shortMonthName: d.toLocaleString('default', { month: 'short' }),
         year: d.getFullYear(),
         weekDay: d.getDay(),
         fullDate: new Date(d),
         ref:
            d.getFullYear() +
            ('0' + d.getMonth()).slice(-2) +
            ('0' + d.getDate()).slice(-2),
      };
      d.setDate(d.getDate() + 1);
   }
   weekDates[0] = {
      day: d.getDate(),
      dayName: days[d.getDay()],
      shortDayName: shortDay[d.getDay()],
      month: d.getMonth(),
      monthName: months[d.getMonth()],
      shortMonthName: d.toLocaleString('default', { month: 'short' }),
      year: d.getFullYear(),
      weekDay: d.getDay(),
      fullDate: new Date(d),
      ref:
         d.getFullYear() + ('0' + d.getMonth()).slice(-2) + ('0' + d.getDate()).slice(-2),
   };
   return weekDates;
};
export const getWeekDatesArray = (date) => {
   // TODO: if the day is a sunday then the schedule loads on the next week because the dayNUmiber goes to -1
   var dayNumber = date.weekDay - 1;

   var d = new Date(date.year, date.month, date.day);
   d.setDate(d.getDate() - dayNumber);
   var weekDates = [];
   for (var i = 0; i < 7; i++) {
      let date =
         d.getFullYear() + ('0' + d.getMonth()).slice(-2) + ('0' + d.getDate()).slice(-2);
      weekDates.push(date);
      d.setDate(d.getDate() + 1);
   }
   return weekDates;
};

export const getDay = (date) => {
   const day = {
      day: date.getDate(),
      dayName: days[date.getDay()],
      shortDayName: shortDay[date.getDay()],
      month: date.getMonth(),
      monthName: months[date.getMonth()],
      shortMonthName: date.toLocaleString('default', { month: 'short' }),
      year: date.getFullYear(),
      weekDay: date.getDay(),
      ref:
         date.getFullYear() +
         ('0' + date.getMonth()).slice(-2) +
         ('0' + date.getDate()).slice(-2),
      fullDate: date,
   };
   return day;
};

export function getMultipleWeeks(date, view) {
   let numWeeks = 2;
   let weeks = [];
   if (view === 'multiweek') {
      numWeeks = 3;

      let prevWeekStart = new Date(date);
      prevWeekStart.setDate(prevWeekStart.getDate() - 7);
      weeks.push(getWeekDates(getDay(prevWeekStart)));
   }
   weeks.push(getWeekDates(getDay(date)));
   if (view !== 'week') {
      for (let i = 1; i < numWeeks; i++) {
         let nextWeekStart = new Date(date);
         nextWeekStart.setDate(nextWeekStart.getDate() + i * 7);
         weeks.push(getWeekDates(getDay(nextWeekStart)));
      }
   }
   for (let week of weeks) {
      const sunday = week.shift();
      week.push(sunday);
   }
   const rearrangedDays = [].concat(...weeks);

   return rearrangedDays;
}
export const isValidDate = (d) => {
   return d instanceof Date && !isNaN(d);
};
export const DateToday = (change) => {
   const date = new Date();
   if (typeof change !== 'undefined') {
      date.setDate(date.getDate() + change);
   }
   const today = {
      day: date.getDate(),
      dayName: days[date.getDay()],
      shortDayName: shortDay[date.getDay()],
      month: date.getMonth(),
      monthName: months[date.getMonth()],
      shortMonthName: date.toLocaleString('default', { month: 'short' }),
      year: date.getFullYear(),
      weekDay: date.getDay(),
      ref:
         date.getFullYear() +
         ('0' + date.getMonth()).slice(-2) +
         ('0' + date.getDate()).slice(-2),
      fullDate: date,
   };
   return today;
};

export function DateTodayDBReady(day) {
   var date = new Date();
   date.setDate(date.getDate() + day);
   const d = ('0' + date.getDate()).slice(-2);
   const m = ('0' + date.getMonth()).slice(-2);
   const today = {
      day: d,
      month: m,
      year: date.getFullYear(),
      weekDay: date.getDay(),
   };
   return today;
}

export function dateDiffInDays(a, b) {
   const _MS_PER_DAY = 1000 * 60 * 60 * 24;
   // Discard the time and time-zone information.
   const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
   const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());

   return Math.floor((utc2 - utc1) / _MS_PER_DAY);
}

export function FullWeekDateRange(start, end, excludeWeekends = false) {
   var d = new Date(start.year, start.month, start.day);
   var last = new Date(end.year, end.month, end.day);
   //calucalting days in milliseconds
   const oneDay = 1000 * 60 * 60 * 24;
   // Calculating the time difference between two dates
   const diffInTime = last.getTime() - d.getTime();
   // Calculating the no. of days between two dates
   const diffInDays = Math.round(diffInTime / oneDay) + 1;
   var weekDates = [];
   for (var i = 0; i < diffInDays; i++) {
      if (excludeWeekends && (d.getDay() === 0 || d.getDay() === 6)) {
         // exclude weekends
         d.setDate(d.getDate() + 1);
         continue;
      }
      let date =
         d.getFullYear() + ('0' + d.getMonth()).slice(-2) + ('0' + d.getDate()).slice(-2);
      weekDates.push(date);
      d.setDate(d.getDate() + 1);
   }
   return weekDates;
}

export function IsInTrial(changeDate) {
   const date = new Date();
   const diffTime = Math.abs(date - changeDate.toDate());
   const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
   if (diffDays < 7) {
      return diffDays;
   } else {
      return false;
   }
}

export function convertToDate(date) {
   //converts date names of docs to readable dates
   var day = date.substring(6, 8);
   var month = Number(date.substring(4, 6)) + 1;
   var year = date.substring(0, 4);

   var formatedDate = day + '/' + month + '/' + year;
   //  + "/" +  + "/" +
   return formatedDate;
}

export function splitDate(dateString) {
   const year = dateString.slice(0, 4);
   const month = dateString.slice(4, 6);
   const day = dateString.slice(6);
   return { year, month, day };
}

export function setupUserRow(
   schedule,
   person,
   weekDates,
   location,
   coLocations,
   archivedLocations,
) {
   let today = DateToday();
   let meSetup = {
      id: person.uid,
      name: person.name.firstName + ' ' + person.name.lastName,
      lid: location.id,
      cid: person.cid,
      date: weekDates,
      clickable: false,
      status: {},
      space: {},
      offsiteLocation: {},
   };
   weekDates.map((date, i) => {
      meSetup.status[i] = { set: '-' };
      meSetup.space[i] = 'empty';
      meSetup.offsiteLocation[i] = null;

      if (location.routine && person.routine && date.ref > today.ref) {
         meSetup.status[i] = {
            ...meSetup.status[i],
            set: person.routine[date.weekDay],
            routine: person.routine[date.weekDay],
         };
      }
      Object.entries(schedule).map(([key, value]) => {
         if (date.ref === key && value.status) {
            if (value.status === 'split') {
               meSetup.status[i].splitValues = {};
               let originalSplit = {};
               let originalLoc = {};
               Object.entries(value.splitDay).map(([time, splitStatus]) => {
                  if (
                     value.lid !== location.id &&
                     location.multisite &&
                     splitStatus === 'ito'
                  ) {
                     meSetup.status[i].splitValues[time] = 'offsite';
                     originalSplit[time] = 'ito';
                     if (coLocations[value.lid] && location.multisite) {
                        originalLoc[time] = {
                           locationName: coLocations[value.lid],
                           locationId: value.lid,
                        };
                     }
                     if (archivedLocations) {
                        if (archivedLocations[value.lid] && location.multisite) {
                           originalLoc[time] = {
                              locationName: archivedLocations[value.lid],
                              locationId: value.lid,
                              locationArchived: true,
                           };
                        }
                     }
                  } else {
                     meSetup.status[i].splitValues[time] = splitStatus;
                  }
               });
            }
            if (
               value.lid !== location.id &&
               location.multisite &&
               value.status === 'ito'
            ) {
               meSetup.status[i] = {
                  ...meSetup.status[i],
                  set: '-',
                  lid: value.lid,
                  originalStatus: value.status,
               };
               if (location.offsiteEnabled) {
                  meSetup.status[i].set = 'offsite';
                  meSetup.status[i].originalStatus = 'ito';
                  // offsiteLocation[i].locationId = value.lid;
                  if (coLocations[value.lid] && location.multisite) {
                     meSetup.offsiteLocation[i] = {
                        locationName: coLocations[value.lid],
                        locationId: value.lid,
                     };
                     // meSetup.status[i].offsiteLocation = {
                     //    locationName: coLocations[value.lid],
                     //    locationId: value.lid,
                     // };
                  }
                  if (archivedLocations) {
                     if (archivedLocations[value.lid] && location.multisite) {
                        meSetup.offsiteLocation[i] = {
                           locationName: archivedLocations[value.lid],
                           locationId: value.lid,
                           locationArchived: true,
                        };
                     }
                  }
               }
               if (value.bookingNo >= 1) {
                  meSetup.space[i] = { ...value.bookings, bookingNo: value.bookingNo };
               }
            } else {
               if (!location.offsiteEnabled && value.status === 'offsite') {
                  meSetup.status[i] = { ...meSetup.status[i], set: '-' };
               } else {
                  meSetup.status[i] = {
                     ...meSetup.status[i],
                     set: value.status,
                     lid: value.lid,
                  };
               }

               if (value.bookingNo >= 1) {
                  meSetup.space[i] = { ...value.bookings, bookingNo: value.bookingNo };
               }
               if (value.offsiteLocation && value.offsiteLocation !== '') {
                  meSetup.offsiteLocation[i] = value.offsiteLocation;
                  meSetup.status[i].originalStatus = 'offsite';
               }
            }
            if (value.customTimes && location.routine) {
               if (meSetup.status[i].set !== '-' && meSetup.status[i].set !== 'away') {
                  meSetup.status[i] = {
                     ...meSetup.status[i],
                     customTimes: true,
                     times: value.times,
                  };
               }
            }
         }
      });
   });
   if (meSetup.id === 'CqFCWJ9s4ZMv3OlCm7tzxk7y4ND2') {
      console.log(meSetup, 'Me');
   }
   return meSetup;
}

export const featureNames = {
   basic: {
      name: 'Basic Attendance Scheduling',
      cost: 'FREE',
   },
   advancedScheduling: {
      name: 'Advanced Scheduling',
      cost: 0.89,
   },
   groups: {
      name: 'Groups',
      cost: 0.2,
   },
   multisite: {
      name: 'Multiple Locations',
      cost: 0.2,
   },
   spaceBookings: {
      name: 'Spaces',
      cost: 1.09,
   },
   reporting: {
      name: 'Reporting',
      cost: 1.0,
   },
   visitors: {
      name: 'Visitors',
      cost: 0.4,
   },
};

export async function chunkObject(object, chunk) {
   var values = Object.values(object);
   var final = [];
   var counter = 0;
   var portion = {};

   for (var key in object) {
      if (counter !== 0 && counter % chunk === 0) {
         final.push(portion);
         portion = {};
      }
      portion[key] = values[counter];
      counter++;
   }
   final.push(portion);

   console.log(final);
   return final;
}

export function stringToColor(string) {
   let hash = 0;
   let i;

   /* eslint-disable no-bitwise */
   for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
   }

   let color = '#';

   for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
   }
   /* eslint-enable no-bitwise */

   // Calculate the luminance of the color
   const luminance =
      0.299 * parseInt(color.substr(1, 2), 16) +
      0.587 * parseInt(color.substr(3, 2), 16) +
      0.114 * parseInt(color.substr(5, 2), 16);

   // Choose black or white text color based on the luminance
   const textColor = luminance > 186 ? '#000000' : '#FFFFFF';

   return { backgroundColor: color, textColor };
}

export function stringAvatar(name) {
   const { backgroundColor, textColor } = stringToColor(name);
   return {
      sx: {
         bgcolor: backgroundColor,
         color: textColor,
         width: 20,
         height: 20,
         fontSize: 10,
      },
      children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
   };
}
