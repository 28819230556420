import { Box } from "@mui/material";
import React, { useState, useEffect } from "react";
import { useAuth } from "../../providers";
import AccountSummary from "../Accounts/allSummary";

const Main = (props) => {
  const { authUser } = useAuth();

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <AccountSummary />
    </Box>
  );
};

export default Main;
