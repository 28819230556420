import React from 'react';

import AuthUserContext from './authUserContext';
import { withFirebase } from '../Firebase';
import { Typography, Button, Container } from '@mui/material';




const needsEmailVerification = (authUser) =>
   authUser &&
   !authUser.emailVerified &&
   authUser.providerData.map((provider) => provider.providerId).includes('password');

const VerificationSent = (props) => {
   
   return (
      <React.Fragment>
         <Typography  component="h1" variant="h1">
            Welcome to intheOffice
         </Typography>
         <Typography  variant="h2">
            Verify your E-mail
         </Typography>
         <Typography  variant="body1">
            We have sent you an E-Mail to verify your new intheOffice account. <br />
            Please check you E-Mails (Spam folder included) for a confirmation E-Mail.{' '}
            <br />
            If you don't receive one in the next few minutes, click on the button below to
            resend.
         </Typography>
      </React.Fragment>
   );
};
const VerificationResent = (props) => {
   // const classes = useStyles();
   return (
      <React.Fragment>
         <Typography  component="h1" variant="h1">
            Welcome to intheOffice
         </Typography>
         <Typography variant="h2">
            E-Mail Verification Resent
         </Typography>
         <Typography variant="body1">
            Please check you E-Mails (Spam folder included) for a confirmation E-Mail.{' '}
            <br />
            Refresh this page once you confirmed your E-Mail.
         </Typography>
      </React.Fragment>
   );
};

const withEmailVerification = (Component) => {
   class WithEmailVerification extends React.Component {
      constructor(props) {
         super(props);

         this.state = { isSent: false };
      }

      onSendEmailVerification = () => {
         this.props.firebase
            .doSendEmailVerification()
            .then(() => this.setState({ isSent: true }));
      };
      render() {
         const { classes } = this.props;
         return (
            <AuthUserContext.Consumer>
               {(authUser) =>
                  needsEmailVerification(authUser) ? (
                     <div style={{ marginTop: '40px' }}>
                        <Container component="main" maxWidth="md">
                           <div>
                              {this.state.isSent ? (
                                 <VerificationResent />
                              ) : (
                                 <VerificationSent />
                              )}

                              <Button
                                 type="submit"
                                 fullWidth
                                 variant="outlined"
                                 color="primary"
                                 //className={classes.submit}
                                 onClick={this.onSendEmailVerification}
                                 disabled={this.state.isSent}
                              >
                                 Resend confirmation E-Mail
                              </Button>
                           </div>
                        </Container>
                     </div>
                  ) : (
                     <Component {...this.props} />
                  )
               }
            </AuthUserContext.Consumer>
         );
      }
   }

   return withFirebase(WithEmailVerification);
};

export default withEmailVerification;
