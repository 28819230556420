import React from "react";

import { compose } from "recompose";
import { AuthProvider } from "../../providers";
import { withAuthorization, withEmailVerification } from "../Session";
import { FirebaseContext } from "../Firebase";
import Main from "./main";

const Accounts = (props) => {
  console.log(props);

  return (
    <AuthProvider>
      <Main />
    </AuthProvider>
  );
};

const condition = (authUser) => !!authUser;

export default compose(
  withEmailVerification,
  withAuthorization(condition)
)(Accounts);
