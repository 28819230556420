import { createContext, useContext, useEffect, useState } from "react";
import { withFirebase } from "../components/Firebase";
import { Box, CircularProgress } from "@mui/material";

const contextName = "Auth";
const AuthContext = createContext(undefined);

const AuthProvider = (props) => {
  console.log(props);
  const { children } = props;
  const [authUser, setAuthUser] = useState(null);
  const [authLoading, setAuthLoading] = useState(true);
  const storedUser = JSON.parse(localStorage.getItem("authUserPersonal"));

  useEffect(() => {
    if (!authUser) {
      props.firebase.user(storedUser.uid).onSnapshot(
        (result) => {
          setAuthUser({
            id: result.id,
            uid: result.id,
            ...result.data(),
          });
          setAuthLoading(false);
        },
        (error) => {
          // TODO: we need to take care of this scenario
          console.log(error, "error");
          setAuthLoading(false);
        }
      );
    }
  }, []);

  return (
    <AuthContext.Provider
      value={{
        authUser,
        authLoading,
      }}
    >
      {authLoading ? (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            height: "100vh",
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        children
      )}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error(
      `You are trying to use this hook outside the ${contextName} provider`
    );
  }

  return context;
};

export default withFirebase(AuthProvider);
