import React, { useEffect } from "react";
import { compose } from "recompose";
import { withRouter } from "react-router-dom";
import { withFirebase } from "../Firebase";
import { withAuthorization, withEmailVerification } from "../Session";
import {
  Button,
  CircularProgress,
  Container,
  Grid,
  Typography,
} from "@mui/material";

const LoggingOut = (props) => {
  useEffect(() => {
    setTimeout(function () {
      props.firebase.doSignOut();
    }, 4000);
  }, [props.firebase]);
  return (
    <Grid item xs={12}>
      <CircularProgress align="center" />
      <Typography variant="body1" align="center">
        ... logging you out
      </Typography>
    </Grid>
  );
};

const LogOut = (props) => {
  return (
    <Container maxWidth="xs">
      <Grid
        style={{ height: "80vh", marginLeft: "-40px" }}
        alignItems="center"
        justify="center"
        align="center"
        direction="row"
        container
      >
        <LoggingOut firebase={props.firebase} />
      </Grid>
    </Container>
  );
};

const LogOutPage = compose(withRouter, withFirebase)(LogOut);

const condition = (authUser) => !!authUser;

export default compose(
  withEmailVerification,
  withAuthorization(condition)
)(LogOutPage);
