import React, { useState, useEffect } from "react";
import {
  Checkbox,
  Collapse,
  Typography,
  Box,
  TableRow,
  TableCell,
  useTheme,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { withFirebase } from "../../Firebase";
import ExplainForm from "./explainForm";
import { Done, QuestionMark, Visibility } from "@mui/icons-material";
import ExplainedTransaction from "./explainedTransaction";

const LineItem = (props) => {
  const theme = useTheme();
  const [transactionStatus, setTransactionStatus] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [checked, setChecked] = useState(false);
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const handleCheckboxChange = (e) => {
    if (checked) {
      props.setSelectedRows((prevSelectedRows) =>
        prevSelectedRows.filter((row) => row.tid !== props.transaction.tid)
      );
    } else {
      props.setSelectedRows((prevSelectedRows) => [
        ...prevSelectedRows,
        {
          tid: props.transaction.tid,
          dateRef: props.transaction.dateRef,
          order: props.transaction.order,
        },
      ]);
    }
    setChecked(!checked);
  };

  useEffect(() => {
    if (props.transaction.reconciled === true) {
      setTransactionStatus("Reconciled");
    } else if (props.transaction.guessed === true) {
      setTransactionStatus("Guessed");
    } else {
      setTransactionStatus("Unreconciled");
    }
  }, [props.transaction.reconciled, props.transaction.estimated]);

  function formatStatusColour(status) {
    switch (status) {
      case "Reconciled":
        return theme.palette.green.main;
      case "Guessed":
        return theme.palette.status.warning;
      default:
        return theme.palette.status.error;
    }
  }
  return (
    <TableRow>
      <TableCell>
        {!props.transaction.reconciled && (
          <Checkbox
            edge="start"
            checked={checked}
            onChange={handleCheckboxChange}
            tabIndex={-1}
            disableRipple
          />
        )}
      </TableCell>
      <TableCell>
        <Typography variant="body2">{props.transaction.date}</Typography>
      </TableCell>
      <TableCell>
        {transactionStatus == "Reconciled" ? (
          <Done sx={{ color: theme.palette.status.success }} />
        ) : transactionStatus == "Guessed" ? (
          <Visibility sx={{ color: theme.palette.status.warning }} />
        ) : (
          <QuestionMark sx={{ color: theme.palette.status.error }} />
        )}
      </TableCell>
      <TableCell sx={{ width: 250, maxWidth: 250 }}>
        <Box onClick={handleExpandClick} sx={{ cursor: "pointer" }}>
          <Typography
            variant="body1"
            sx={{
              color: formatStatusColour(transactionStatus),
              fontWeight: "bold",
            }}
          >
            {props.transaction.counterParty !== "" &&
              props.transaction.counterParty}
            {props.transaction.type !== "" &&
              props.transaction.type !== null &&
              ` (${props.transaction.type})`}
          </Typography>
          {props.transaction.reference !== "" && (
            <Typography variant="body2">
              {props.transaction.reference}
            </Typography>
          )}
          {props.transaction.category !== "" && (
            <Typography variant="body2">
              {props.transaction.category}
            </Typography>
          )}
        </Box>

        <Collapse in={expanded} timeout="auto" unmountOnExit>
          {props.transaction.reconciled === true ? (
            <ExplainedTransaction
              transaction={props.transaction}
              loadedAccount={props.loadedAccount}
              setExpanded={setExpanded}
            />
          ) : (
            <ExplainForm
              transaction={props.transaction}
              loadedAccount={props.loadedAccount}
              setExpanded={setExpanded}
            />
          )}
        </Collapse>
      </TableCell>
      {/* <TableCell>
          <IconButton edge="end" aria-label="edit">
            <EditIcon />
          </IconButton>
          <IconButton edge="end" aria-label="delete">
            <DeleteIcon />
          </IconButton>
          <ExpandMoreIcon
            style={{
              transform: expanded ? "rotate(180deg)" : "rotate(0deg)",
            }}
          />
        </TableCell> */}
      <TableCell>
        {props.transaction.amount > 0 && props.transaction.amount.toFixed(2)}
      </TableCell>
      <TableCell>
        {props.transaction.amount < 0 && -props.transaction.amount.toFixed(2)}
      </TableCell>
      <TableCell>{props.transaction.balance.toFixed(2)}</TableCell>
    </TableRow>
  );
};
export default withFirebase(LineItem);
