import { TableCell, TableRow, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { withFirebase } from "../../Firebase";
import firebase from "firebase/compat/app";
import { useAuth } from "../../../providers";

const CarriedForwardBalance = (props) => {
  const { authUser } = useAuth();
  const [carriedBalance, setCarriedBalance] = useState(0);

  useEffect(() => {
    const unsubscribe = props.firebase.db
      .collection(
        `users/${authUser.uid}/accounts/${props.loadedAccount.aid}/transactionDates`
      )
      .where(
        firebase.firestore.FieldPath.documentId(),
        "<",
        `${props.transactionMonth.year}${(
          "0" + props.transactionMonth.month
        ).slice(-2)}01`
      )
      .orderBy(firebase.firestore.FieldPath.documentId(), "desc")
      .limit(1)
      .onSnapshot((results) => {
        if (!results.empty) {
          let balance = results.docs[0].data().balances.end;
          setCarriedBalance(balance);
        } else {
          setCarriedBalance(0);
        }
      });
    return () => {
      unsubscribe();
    };
  }, [props.transactionMonth, props.loadedAccount.aid]);
  return (
    <TableRow>
      <TableCell></TableCell>
      <TableCell></TableCell>
      <TableCell></TableCell>
      <TableCell>
        <Typography variant="caption" sx={{ fontStyle: "italic" }}>
          Balance Carried Forward
        </Typography>
      </TableCell>
      <TableCell></TableCell>
      <TableCell></TableCell>
      <TableCell>
        <Typography variant="caption" sx={{ fontStyle: "italic" }}>
          {carriedBalance.toFixed(2)}
        </Typography>
      </TableCell>
    </TableRow>
  );
};

export default withFirebase(CarriedForwardBalance);
