import firebase from "firebase/compat/app";
// import "firebase/compat/firestore";

export const createBatch = () => firebase.firestore().batch();
export const increment = (i = 1) => firebase.firestore.FieldValue.increment(i);
export const deleteField = () => firebase.firestore.FieldValue.delete();

export const newTermRef = (uid, dbTerm, id) =>
  firebase.firestore().doc(`users/${uid}/${dbTerm}/${id}`);
export const termTransactionDateRef = (uid, dbTerm, id, dateRef) =>
  firebase
    .firestore()
    .doc(`users/${uid}/${dbTerm}/${id}/transactions/${dateRef}`);
export const doubleEntryTransactionDateRef = (uid, dateRef) =>
  firebase
    .firestore()
    .doc(`users/${uid}/counterparties/${uid}/transactions/${dateRef}`);
export const transactionRef = (uid, aid, tid) =>
  firebase
    .firestore()
    .doc(`users/${uid}/accounts/${aid}/allTransactions/${tid}`);
export const termRef = (uid, dbTerm, id) =>
  firebase.firestore().doc(`users/${uid}/${dbTerm}/${id}`);

export const doubleEntryMeRef = (uid) =>
  firebase.firestore().doc(`users/${uid}/counterparties/${uid}`);

export const transDateRef = (uid, aid, dateRef) =>
  firebase
    .firestore()
    .doc(`users/${uid}/accounts/${aid}/transactionDates/${dateRef}`);
export const accountRef = (uid, aid) =>
  firebase.firestore().doc(`users/${uid}/accounts/${aid}`);

export const termIndexRef = (uid, term) =>
  firebase.firestore().doc(`users/${uid}/indexes/${term}`);
