import React, { useEffect, useState } from "react";
import LoadingThrobber from "../../Layout/LoadingThrobber";
import { withFirebase } from "../../Firebase";
import firebase from "firebase/compat/app";
import { Box, TextField, Button, Typography } from "@mui/material";
import { useAuth } from "../../../providers";
import ExplainAuto from "./ExplainForm/explainAuto";

import explainTransferTransaction from "../functions/explainTransferTransaction";
import { explainNonTransferTransaction } from "./functions/explainNonTransferTransaction";

const ExplainForm = (props) => {
  const { authUser } = useAuth();
  const [form, setForm] = useState(false);
  const [counterParties, setCounterParties] = useState(false);
  const [paymentCategory, setPaymentCategory] = useState(false);
  const [transactionTypes, setTransactionTypes] = useState(false);
  const [projects, setProjects] = useState(false);
  const [assets, setAssets] = useState(false);
  const [accounts, setAccounts] = useState(false);

  useEffect(() => {
    const unsubscribe = props.firebase.db
      .doc(`users/${authUser.uid}/indexes/paymentCategories`)
      .onSnapshot((snapshot) => {
        if (snapshot.exists) {
          let categories = [];
          Object.entries(snapshot.data()).map(([key, category]) => {
            categories.push({ id: key, label: category.title, ...category });
          });

          setPaymentCategory(categories);
        } else {
          setPaymentCategory([]);
        }
      });
    return () => unsubscribe();
  }, []);
  useEffect(() => {
    const unsubscribe = props.firebase.db
      .doc(`users/${authUser.uid}/indexes/transactionTypes`)
      .onSnapshot((snapshot) => {
        if (snapshot.exists) {
          let types = [];
          Object.entries(snapshot.data()).map(([key, type]) => {
            types.push({ id: key, label: type.title, ...type });
          });

          setTransactionTypes(types);
        } else {
          setTransactionTypes([]);
        }
      });
    return () => unsubscribe();
  }, []);

  useEffect(() => {
    const unsubscribe = props.firebase.db
      .doc(`users/${authUser.uid}/indexes/projects`)
      .onSnapshot((snapshot) => {
        let projects = [];
        if (snapshot.exists) {
          Object.entries(snapshot.data()).map(([key, project]) => {
            projects.push({ id: key, label: project.title, ...project });
          });
        }
        setProjects(projects);
      });
    return () => unsubscribe();
  }, []);
  useEffect(() => {
    const unsubscribe = props.firebase.db
      .doc(`users/${authUser.uid}/indexes/assets`)
      .onSnapshot((snapshot) => {
        let data = [];
        if (snapshot.exists) {
          Object.entries(snapshot.data()).map(([key, asset]) => {
            data.push({ id: key, label: asset.title, ...asset });
          });
        }
        setAssets(data);
      });
    return () => unsubscribe();
  }, []);

  useEffect(() => {
    const unsubscribe = props.firebase.db
      .doc(`users/${authUser.uid}/indexes/counterparties`)
      .onSnapshot((snapshot) => {
        if (snapshot.exists) {
          let parties = [];
          Object.entries(snapshot.data()).map(([key, type]) => {
            parties.push({ id: key, label: type.title, ...type });
          });

          setCounterParties(parties);
        } else {
          setCounterParties([]);
        }
      });
    return () => unsubscribe();
  }, []);
  useEffect(() => {
    const unsubscribe = props.firebase.db
      .doc(`users/${authUser.uid}/indexes/accounts`)
      .onSnapshot((snapshot) => {
        if (snapshot.exists) {
          let acc = [];
          Object.entries(snapshot.data()).map(([key, category]) => {
            if (key !== props.loadedAccount.aid) {
              acc.push({ id: key, label: category.title, ...category });
            }
          });

          setAccounts(acc);
        } else {
          setAccounts([]);
        }
      });
    return () => unsubscribe();
  }, []);
  useEffect(() => {
    if (
      props.transaction.tid &&
      props.loadedAccount.aid &&
      transactionTypes &&
      paymentCategory
    ) {
      const unsubscribe = props.firebase.db
        .doc(
          `users/${authUser.uid}/accounts/${props.loadedAccount.aid}/allTransactions/${props.transaction.tid}`
        )
        .onSnapshot(
          (snapshot) => {
            let data = {
              counterParty: "",
              category: "",
              type: "",
              reference: "",
              description: "",
              attachment: null,
              project: null,
              asset: null,
              split: false,
              party: null,
              reconciled: false,
              estimated: false,
              account: "",
            };
            if (snapshot.exists) {
              if (snapshot.data().account && accounts) {
                data = {
                  ...data,
                  ...snapshot.data(),
                  account: accounts.find(
                    (option) => option.id === snapshot.data().account
                  )?.label,
                };
              } else {
                data = { ...data, ...snapshot.data() };
              }
            }
            setForm(data);
          },
          (error) => {
            console.error(error, "ERROR LOADING TRANSACTION");
          }
        );

      return () => {
        unsubscribe();
      };
    }
  }, [
    props.transaction.tid,
    props.loadedAccount.aid,
    transactionTypes,
    paymentCategory,
    accounts,
  ]);

  function handleClose() {
    setForm(false);
    props.setExpanded(false);
  }
  async function explainTransaction() {
    if (form.category === "Transfer") {
      if (form.account && form.account !== "") {
        explainTransferTransaction(
          authUser,
          form,
          props.transaction,
          props.loadedAccount,
          accounts,
          true
        ).then(() => handleClose());
      } else {
        alert("you must add an account to explain this transaction");
      }
    } else {
      explainNonTransferTransaction(
        authUser.uid,
        props.loadedAccount.aid,
        props.transaction,
        form,
        true
      ).then(() => handleClose());
    }
  }

  return (
    <Box sx={{ mt: 4 }}>
      {form && (
        <Box>
          {counterParties && (
            <ExplainAuto
              options={counterParties}
              type="counterParty"
              form={form}
              setForm={setForm}
              typeLabel="Counterparty"
            />
          )}
          {paymentCategory && (
            <ExplainAuto
              options={paymentCategory}
              type="category"
              form={form}
              setForm={setForm}
              typeLabel="Payment Category"
            />
          )}
          {form.category === "Transfer" && accounts && (
            <ExplainAuto
              options={accounts}
              type="account"
              form={form}
              setForm={setForm}
              typeLabel="Account"
            />
          )}
          {form.category !== "Transfer" && transactionTypes && (
            <ExplainAuto
              options={transactionTypes}
              type="type"
              form={form}
              setForm={setForm}
              typeLabel="Transaction Type"
            />
          )}
          <TextField
            label="Reference"
            size="small"
            fullWidth
            value={form.reference}
            onChange={(e) => setForm({ ...form, reference: e.target.value })}
            sx={{ mt: 1 }}
          />
          {projects && (
            <ExplainAuto
              options={projects}
              type="project"
              form={form}
              setForm={setForm}
              typeLabel="Project"
            />
          )}
          {assets && (
            <ExplainAuto
              options={assets}
              type="assets"
              form={form}
              setForm={setForm}
              typeLabel="Assets"
            />
          )}
          <Typography variant="caption" sx={{ mt: 2 }}>
            If any of the fields contain new items they will be added to your
            lists when you explain the transaction.
          </Typography>
          <Box sx={{ display: "flex", justifyContent: "space-between", mt: 2 }}>
            <Button
              onClick={() => explainTransaction()}
              variant="contained"
              color="secondary"
            >
              Explain
            </Button>
            <Button onClick={() => handleClose()}>Cancel</Button>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default withFirebase(ExplainForm);
