import React, { useEffect, useState } from "react";
import CardSection from "../Layout/CardSection";
import { withFirebase } from "../Firebase";
import { useAuth } from "../../providers";
import LoadingThrobber from "../Layout/LoadingThrobber";
import {
  Box,
  Divider,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@mui/material";
import Amount from "./amount";

const AllParties = ({ firebase, filter }) => {
  const { authUser } = useAuth();
  const [counterParties, setCounterParties] = useState(false);

  function sortByOrderAndName(a, b) {
    if (a.favourite !== b.favourite) {
      return a.favourite ? -1 : 1;
    }
    if (a.order !== b.order) {
      return a.order - b.order;
    }
    return a.title.localeCompare(b.title);
  }

  useEffect(() => {
    if (filter !== null) {
      const unsubscribe = firebase.db
        .collection(`users/${authUser.uid}/${filter}`)
        .onSnapshot((snapshot) => {
          console.log(snapshot);
          if (!snapshot.empty) {
            let parties = [];
            snapshot.forEach((doc) => {
              parties.push({ ...doc.data(), id: doc.id });
            });
            const sortedParties = [...parties].sort(sortByOrderAndName);
            setCounterParties(sortedParties);
          } else {
            setCounterParties(null);
          }
        });
      return () => {
        unsubscribe();
      };
    }
  }, [filter]);

  if (counterParties) {
    return (
      <Box sx={{ display: "flex", flexWrap: "wrap" }}>
        {counterParties.map((party) => (
          <CardSection
            key={party.id}
            sx={{ minWidth: 200, maxWidth: 200, m: 1 }}
          >
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Typography variant="body1">{party.title}</Typography>
              {party.favourite && <Typography variant="body1">⭐</Typography>}
            </Box>
            <List dense>
              <ListItem>
                <ListItemText
                  secondary={<Amount amount={party.credit} label="Credit" />}
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  secondary={<Amount amount={party.debit} label="Debit" />}
                />
              </ListItem>
              <Divider />
              <ListItem>
                <ListItemText
                  secondary={<Amount amount={party.balance} label="Balance" />}
                />
              </ListItem>
              {party.loan !== 0 && (
                <ListItem sx={{ mt: 2 }}>
                  <ListItemText
                    secondary={<Amount amount={party.loan} label="Loan" />}
                  />
                </ListItem>
              )}
            </List>
          </CardSection>
        ))}
      </Box>
    );
  } else if (counterParties === null) {
    return <CardSection>No {filter}</CardSection>;
  } else {
    return <LoadingThrobber />;
  }
};
export default withFirebase(AllParties);
