import { Box } from "@mui/material";
import React from "react";

const Amount = ({ amount, label }) => {
  function loanAmount(value) {
    if (value > 0) {
      return `${value.toFixed(2)}`;
    } else if (value < 0) {
      return `${Math.abs(value).toFixed(2)}`;
    } else {
      return `No loan`;
    }
  }
  return (
    <Box
      sx={{ display: "flex", justifyContent: "space-between" }}
      component="span"
    >
      <Box component="span">
        {label === "Loan" && amount > 0
          ? "Loaned:"
          : label === "Loan" && amount < 0
          ? "Owed:"
          : `${label}:`}{" "}
      </Box>
      <Box component="span">
        {label === "Loan" ? loanAmount(amount) : `${amount.toFixed(2)}`}
      </Box>
    </Box>
  );
};

export default Amount;
