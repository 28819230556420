import React from "react";

import { compose } from "recompose";
import { AuthProvider } from "../../providers";
import { withAuthorization, withEmailVerification } from "../Session";
import { FirebaseContext } from "../Firebase";
import Main from "./main";

const Dashboard = (props) => {
  console.log(props);
  //  <FirebaseContext.Consumer>
  {
    /* {(firebase) => { */
  }
  return (
    <AuthProvider>
      <Main />
    </AuthProvider>
  );
  //  }}
  //  </FirebaseContext.Consumer>
};

const condition = (authUser) => !!authUser;

export default compose(
  withEmailVerification,
  withAuthorization(condition)
)(Dashboard);
