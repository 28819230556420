import { termIndexRef } from "../firebaseRefs";

export const returnTermArray = async (uid, dbTerm) => {
  const doc = await termIndexRef(uid, dbTerm).get();
  let array = [];
  if (doc.exists) {
    Object.entries(doc.data()).map(([key, value]) => {
      array.push({ id: key, ...value });
    });
  }
  return array;
};
