import { Box, Button } from "@mui/material";
import React, { useState } from "react";
import { withFirebase } from "../Firebase";
import { useAuth } from "../../providers";
import { dbTerms } from "../../constants/transactionFields";
import LoadingThrobber from "../Layout/LoadingThrobber";
const ResetAccount = (props) => {
  const { authUser } = useAuth();
  const [loading, setLoading] = useState(false);
  const paymentCategories = {
    1: { title: "Income", order: 1 },
    2: { title: "Payment", order: 2 },
    3: { title: "Loan", order: 3 },
    4: { title: "Gift", order: 4 },
    5: { title: "Transfer", order: 5 },
  };

  console.log(authUser);
  async function deleteAllDocuments() {
    setLoading(true);
    try {
      await deleteCollection(`users/${authUser.uid}/indexes`);
      const accounts = await props.firebase.db
        .collection(`users/${authUser.uid}/accounts`)
        .get();

      await accounts.forEach(async (doc) => {
        await deleteCollection(
          `users/${authUser.uid}/accounts/${doc.id}/allTransactions`
        );
        await deleteCollection(
          `users/${authUser.uid}/accounts/${doc.id}/transactionDates`
        );
      });
      await deleteCollection(`users/${authUser.uid}/accounts`);
      await Promise.all(
        dbTerms.map(async (termName) => {
          const term = await props.firebase.db
            .collection(`users/${authUser.uid}/${termName}`)
            .get();
          if (!term.empty) {
            await term.forEach(async (doc) => {
              await deleteCollection(
                `users/${authUser.uid}/${termName}/${doc.id}/transactions`
              );
              await deleteCollection(`users/${authUser.uid}/${termName}`);
            });
          }
        })
      );

      await props.firebase.db
        .doc(`users/${authUser.uid}/indexes/paymentCategories`)
        .set(paymentCategories);
      console.log("step 1");
      await Promise.all(
        Object.entries(paymentCategories).map(async ([key, value]) => {
          await props.firebase.db
            .doc(`users/${authUser.uid}/paymentCategories/${key}`)
            .set({
              balance: 0,
              credit: 0,
              debit: 0,
              favourite: false,
              loan: 0,
              title: `${value.title}`,
              order: 1,
            });
          console.log(value, "UPDATEd");
        })
      );
      console.log("step 2");
      await props.firebase.db
        .doc(`users/${authUser.uid}/indexes/counterparties`)
        .set({
          [authUser.uid]: {
            title: `${authUser.name.firstName} ${authUser.name.lastName} (ME)`,
            order: 1,
          },
        });
      console.log("step 3");
      await props.firebase.db
        .doc(`users/${authUser.uid}/counterparties/${authUser.uid}`)
        .set({
          balance: 0,
          credit: 0,
          debit: 0,
          favourite: true,
          loan: 0,
          title: `${authUser.name.firstName} ${authUser.name.lastName} (ME)`,
          order: 1,
        });

      await props.firebase.db.doc(`users/${authUser.uid}`).set({
        name: authUser.name,
        email: authUser.email,
        uid: authUser.uid,
      });
      setLoading(false);
    } catch (err) {
      console.error(err, "Error deleting indexes");
    }
  }

  async function deleteCollection(collectionPath) {
    try {
      console.log(collectionPath);
      const collectionRef = await props.firebase.db.collection(collectionPath);
      const querySnapshot = await collectionRef.get();
      if (!querySnapshot.empty) {
        // for (const doc of querySnapshot.docs) {
        //   console.log(`${collectionPath}/${doc.id}`);
        //   await deleteCollection(`${collectionPath}/${doc.id}`);
        // }
        // Split documents into batches of 500 (Firestore limit)
        const batchSize = 500;
        const chunks = [];
        for (let i = 0; i < querySnapshot.size; i += batchSize) {
          chunks.push(querySnapshot.docs.slice(i, i + batchSize));
        }

        // Delete documents in each batch
        for (const chunk of chunks) {
          const batch = props.firebase.db.batch();
          chunk.forEach((doc) => {
            batch.delete(doc.ref);
          });
          await batch.commit();
        }

        console.log(
          `All documents in the "${collectionPath}" collection have been deleted.`
        );
      }
    } catch (error) {
      console.error("Error deleting documents:", error);
    }
  }

  return (
    <Box>
      {loading ? (
        <LoadingThrobber />
      ) : (
        <Button disabled={false} onClick={() => deleteAllDocuments()}>
          Reset your account
        </Button>
      )}
    </Box>
  );
};

export default withFirebase(ResetAccount);
