import { Box, Stack, Typography, useMediaQuery } from "@mui/material";
import React, { useEffect, useState } from "react";
import PageLayout from "../Layout/PageLayout";
import { useAuth } from "../../providers";
import CardSection from "../Layout/CardSection";
import OwnerFilter from "../Filters/OwnerFilter";
import AccountFilter from "../Filters/AccountFilter";
import Summary from "./summary";
import { withFirebase } from "../Firebase";
import Imports from "./imports";
import Transactions from "./Transactions/transactions";

const Main = (props) => {
  const { authUser } = useAuth();
  const [owner, setOwner] = useState(authUser);
  const [account, setAccount] = useState(false);
  const [loadedAccount, setLoadedAccount] = useState(false);
  const isXs = useMediaQuery((theme) => theme.breakpoints.down("xs"));

  return (
    <PageLayout>
      <Box
        sx={{
          display: "flex",
          flexDirection: isXs ? "column" : "row",
          width: "100%",
        }}
      >
        <CardSection sx={{ width: isXs ? "100%" : "60%" }}>
          <Summary
            setLoadedAccount={setLoadedAccount}
            loadedAccount={loadedAccount}
            setAccount={setAccount}
            account={account}
            owner={owner}
            setOwner={setOwner}
          />
        </CardSection>
        <CardSection sx={{ ml: 2, width: isXs ? "100%" : "40%" }}>
          <Imports loadedAccount={loadedAccount} />
        </CardSection>
      </Box>
      <Box>
        {!loadedAccount ? (
          <Typography variant="body1">Nothing to display</Typography>
        ) : (
          <Transactions loadedAccount={loadedAccount} />
        )}
      </Box>
    </PageLayout>
  );
};

export default withFirebase(Main);
